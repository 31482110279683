<template>
    <div style="font-size: 0.9rem;">
        <b-row class="row mb-0">
            <b-col class="col-xl-12 mb-0">
                <b-card class="mb-1">
                    <b-row class="">
                        <b-col sm="12">
                            <b-row class="font-weight-bolder">
                                <b-col sm="3">
                                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="WMO ID" label-for="input-sm">
                                        <v-select v-model="t_wmoid" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="WMO ID" :options="t_wmoid_list" class="select-size-sm" />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="3">
                                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Observer" label-for="input-sm">
                                        <v-select
                                            v-model="$v.t_observer.$model"
                                            required
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            label="observer"
                                            :reduce="(option) => option.id"
                                            :options="list_observer"
                                            :state="validateState($v.t_observer)"
                                            class="select-size-sm"
                                        />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="3">
                                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Tanggal" label-for="datepicker-2">
                                        <!-- <b-form-datepicker id="datepicker-dateformat2" size="sm" placeholder="Date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="en" v-model="date" -->
                                        <b-form-datepicker
                                            id="datepicker-2"
                                            :date-format-options="{
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                            }"
                                            locale="en"
                                            size="sm"
                                            :max="max"
                                            placeholder="tanggal"
                                            v-model="tanggal"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-group label-cols="4" label-cols-lg="3" label-size="sm" label="Jam" label-for="input-sm">
                                        <!-- <b-form-select v-model="jam" class="mb-0" size="sm">
											<option value="" disabled>- Select -</option>
											<option value="00:00">00:00</option>
											<option value="06:00">06:00</option>
											<option value="12:00">12:00</option>
											<option value="18:00">18:00</option>										
                    </b-form-select> -->
                                        <v-select v-model="jam" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="Jam" :options="t_jam_list" class="select-size-sm" />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <div v-if="formShowed">
                        <hr />
                        <b-row class="mb-n2">
                            <b-col sm="3">
                                <b-form-group label-cols="4" label-cols-lg="7" label-size="sm" label="Arah Angin Perm (*)" label-for="input-sm">
                                    <template #label>
                                        Arah Angin Perm (*)<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Arah angin permukaan sesuai pada jam pengamatan " />
                                    </template>
                                    <!-- <b-form-input id="input-arah" size="sm" class="text-center" type="number" v-on:keyup="checkValidate($event, 'input-arah')" v-bind:class="{rangecheck:isRangeCheck}"  :state="validateState($v.WIN_DIR_SURFACE)" v-model="$v.WIN_DIR_SURFACE.$model"></b-form-input> -->
                                    <b-form-input
                                        :formatter="format_one_decimal"
                                        id="input-arah"
                                        size="sm"
                                        class="text-center"
                                        v-on:keydown="onKeyCheck($event)"
                                        :state="validateState($v.WIN_DIR_SURFACE)"
                                        v-model.number="$v.WIN_DIR_SURFACE.$model"
                                        v-on:keypress="isNumber($event)"
                                    ></b-form-input>
                                    <!-- <b-tooltip v-if="gross_check_tooltip('WIN_DIR_SURFACE').status" :triggers="'hover'" target="input-arah" placement="top" custom-class="validation-tooltip">
                    {{ gross_check_tooltip('WIN_DIR_SURFACE').message }}
                  </b-tooltip> -->
                                    <b-tooltip :triggers="'hover'" target="input-arah" placement="top" custom-class="validation-tooltip">
                                        {{ tooltipMessage("WIN_DIR_SURFACE") }}
                                    </b-tooltip>
                                </b-form-group>
                            </b-col>

                            <b-col sm="3">
                                <b-form-group label-cols="4" label-cols-lg="7" label-size="sm" label="Kec Angin Perm (kt)" label-for="input-sm">
                                    <template #label>
                                        Kec Angin Perm (kt)<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Arah angin permukaan sesuai pada jam pengamatan  " />
                                    </template>
                                    <b-form-input
                                        :formatter="format_one_decimal"
                                        id="input-kec"
                                        size="sm"
                                        class="text-center"
                                        v-on:keydown="onKeyCheck($event)"
                                        :state="validateState($v.WIN_SPEED_SURFACE)"
                                        v-model.number="$v.WIN_SPEED_SURFACE.$model"
                                        v-on:keypress="isNumber($event)"
                                    ></b-form-input>
                                    <!-- <b-tooltip v-if="gross_check_tooltip('WIN_SPEED_SURFACE').status" :triggers="'hover'" target="input-arah" placement="top" custom-class="validation-tooltip">
                    {{ gross_check_tooltip('WIN_SPEED_SURFACE').message }}
                  </b-tooltip> -->
                                    <b-tooltip :triggers="'hover'" target="input-kec" placement="top" custom-class="validation-tooltip">
                                        {{ tooltipMessage("WIN_SPEED_SURFACE") }}
                                    </b-tooltip>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Elevasi (feet)" label-for="input-sm">
                                    <template #label> Elevasi (feet)<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Elevasi stasiun dalam satuan Feet  " /> </template>
                                    <b-form-input
                                        id="input-elevasi"
                                        size="sm"
                                        class="text-center"
                                        type="number"
                                        v-on:keydown="onKeyCheck($event)"
                                        :state="validateState($v.ELEVASI_STATION)"
                                        v-model="$v.ELEVASI_STATION.$model"
                                        readonly="readonly"
                                    ></b-form-input>

                                    <b-tooltip :triggers="'hover'" target="input-elevasi" placement="top" custom-class="validation-tooltip">
                                        {{ tooltipMessage("ELEVASI_STATION") }}
                                    </b-tooltip>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label-cols="4" label-cols-lg="3" label-size="sm" label="Alat" label-for="input-sm">
                                    <v-select
                                        v-model="$v.ALAT_PIBAL.$model"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="label"
                                        :reduce="(option) => option.id"
                                        :options="t_alat_list"
                                        :state="validateState($v.ALAT_PIBAL)"
                                        class="select-size-sm"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>
            </b-col>
        </b-row>

        <b-row v-if="formShowed">
            <b-col cols="12">
                <b-form>
                    <b-row class="row">
                        <b-col cols="7" class="col-xl-7 pr-0">
                            <b-card>
                                <b-container fluid class="">
                                    <b-row>
                                        <b-col cols="5" class="">
                                            <b-row class="text-center mb-2">
                                                <b-col cols="4 pad2">
                                                    <label for="input-text" class="font-weight-bolder">Pembacaan</label>
                                                </b-col>
                                                <b-col cols="4">
                                                    <label for="input-text" class="font-weight-bolder">Azimut</label>
                                                </b-col>
                                                <b-col cols="4">
                                                    <label for="input-text" class="font-weight-bolder">Elevasi</label>
                                                </b-col>
                                            </b-row>
                                            <b-row class="row-margin" v-for="(item, index) in PEMBACAAN" :key="index">
                                                <b-col cols="4" class="text-center mt-0 m-auto badge badge-light-success">
                                                    <label for="input-text">{{ index + 1 }}</label>
                                                </b-col>
                                                <b-col cols="4 pad1">
                                                    <b-form-input
                                                        v-on:keydown="onKeyCheck($event)"
                                                        :id="`inputAzimut-${index}`"
                                                        :formatter="formatAzimut"
                                                        :state="validateState($v.PEMBACAAN.$each[index].azimut)"
                                                        v-model="$v.PEMBACAAN.$each[index].azimut.$model"
                                                        size="sm"
                                                        class="text-center"
                                                        type="number"
                                                        :class="item.st_hitung == false ? '' : ''"
                                                        style="color: black !important;"
                                                    >
                                                    </b-form-input>
                                                    <b-tooltip v-if="item.st_hitung == false" :triggers="'hover'" target="inputAzimut-0" placement="top" custom-class="validation-tooltip">
                                                        {{ firstReadingMessage }}
                                                    </b-tooltip>
                                                    <b-tooltip v-else-if="index === 0" :triggers="'hover'" target="inputAzimut-0" placement="top" custom-class="validation-tooltip">
                                                        {{ firstReadingMessage }}
                                                    </b-tooltip>
                                                </b-col>
                                                <b-col cols="4 pad1">
                                                    <b-form-input
                                                        v-on:keydown="onKeyCheck($event)"
                                                        :id="`inputElevasi-${index}`"
                                                        :formatter="formatElevasi"
                                                        :state="validateState($v.PEMBACAAN.$each[index].elevasi)"
                                                        v-model="$v.PEMBACAAN.$each[index].elevasi.$model"
                                                        size="sm"
                                                        class="text-center"
                                                        type="number"
                                                        :class="item.st_hitung == false ? '' : ''"
                                                        v-on:keydown.tab="onTab($event, index)"
                                                        style="color: black !important;"
                                                    >
                                                    </b-form-input>
                                                    <b-tooltip v-if="item.st_hitung == false" :triggers="'hover'" target="inputElevasi-0" placement="top" custom-class="validation-tooltip">
                                                        {{ firstReadingMessage }}
                                                    </b-tooltip>
                                                    <b-tooltip v-else-if="index === 0" :triggers="'hover'" target="inputElevasi-0" placement="top" custom-class="validation-tooltip">
                                                        {{ firstReadingMessage }}
                                                    </b-tooltip>
                                                    <!-- <b-tooltip :show="validateTooltipState($v.PEMBACAAN.$each[index].elevasi)" :triggers="'manual'" target="`inputElevasi-${index}`" placement="top" custom-class="validation-tooltip">
                            This is a required field.
                          </b-tooltip> -->
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <!-- ==inpur right -->
                                        <b-col class="col-lg-7">
                                            <b-row class="text-center mt-1 mb-n1">
                                                <b-col cols="3">
                                                    <label for="input-text" class="font-weight-bolder badge badge-light-primary">Level</label>
                                                </b-col>
                                                <b-col cols="3">
                                                    <label for="input-text" class="font-weight-bolder badge badge-light-success">Arah</label>
                                                </b-col>
                                                <b-col cols="3 pad2">
                                                    <label for="input-text" class="font-weight-bolder badge badge-light-warning">Kec Angin</label>
                                                </b-col>
                                                <b-col cols="3">
                                                    <label for="input-text" class="font-weight-bolder badge badge-light-info"></label>
                                                </b-col>
                                            </b-row>

                                            <!-- <b-row class="text-center row-level2" v-for="indexLAKD in minNumPembacaan / 2" :key="indexLAKD"> -->
                                            <b-row
                                                class="text-center"
                                                v-for="indexLAKD in LEVEL.length"
                                                :key="indexLAKD"
                                                :class="[{ rowlvl2: LEVEL[indexLAKD - 1] != -1 }, { hide: LEVEL[indexLAKD - 1] == -1 }, { rowlvl3: indexLAKD > 1 && LEVEL[indexLAKD - 2] == -1 }]"
                                            >
                                                <b-col cols="3 pad1">
                                                    <b-form-input id="input" v-model="LEVEL[indexLAKD - 1]" size="sm" type="number" class="bg-light-secondary text-center" readonly style="color: black !important;"> </b-form-input>
                                                </b-col>
                                                <b-col cols="3 pad1">
                                                    <b-form-input v-if="WD[indexLAKD - 1] == -999" id="wd-1" value="///" size="sm" class="bg-light-secondary text-center" readonly style="color: black !important;"></b-form-input>
                                                    <b-form-input v-else id="wd1" v-model="WD[indexLAKD - 1]" size="sm" type="number" class="bg-light-secondary text-center" readonly style="color: black !important;"> </b-form-input>
                                                </b-col>
                                                <b-col cols="3 pad1">
                                                    <b-form-input v-if="WS[indexLAKD - 1] == -999" id="ws-1" value="//" size="sm" class="bg-light-secondary text-center" readonly style="color: black !important;"></b-form-input>
                                                    <b-form-input v-else id="ws1" v-model="WS[indexLAKD - 1]" size="sm" type="number" class="bg-light-secondary text-center" readonly style="color: black !important;"> </b-form-input>
                                                </b-col>
                                                <b-col cols="3 pad1">
                                                    <b-form-input v-if="dddff[indexLAKD - 1].length > 5 || dddff[indexLAKD - 1] == 'NaNaN'" id="input" size="sm" class="bg-light-secondary text-center" readonly style="color: black !important;">
                                                    </b-form-input>
                                                    <b-form-input v-else id="input" v-model="dddff[indexLAKD - 1]" size="sm" class="bg-light-secondary text-center" readonly style="color: black !important;"> </b-form-input>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-1">
                                                <b-col cols="9">
                                                    <div>
                                                        <b-button-group class="btn-group-sm">
                                                            <b-button v-b-tooltip.hover.v-primary title="Tambah 1 Row Pembacaan" variant="gradient-primary" class="btn-icon mr" v-on:click="addPembacaan">
                                                                <feather-icon icon="PlusCircleIcon" />
                                                            </b-button>
                                                            <!-- <b-button
                                v-b-tooltip.hover.v-success
                                title="Edit"
                                variant="gradient-success"
                                class="btn-icon mr"
                                v-on:click="editPembacaan"
                              >
                                <feather-icon icon="Edit3Icon" />
                              </b-button> -->
                                                            <b-button v-b-tooltip.hover.v-danger title="Hapus 1 Row Pembacaan" variant="gradient-danger" class="btn-icon mr" v-on:click="deletePembacaan">
                                                                <feather-icon icon="Trash2Icon" />
                                                            </b-button>
                                                        </b-button-group>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>

                                    <b-row class="mt-2">
                                        <b-col cols="4" class="mt-0">
                                            <label for="input-text"
                                                >Alasan Penghentian
                                                <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Alasan Penghentian Pengamatan Pilot Balon dikarenakan beberapa faktor"
                                            /></label>
                                        </b-col>
                                        <b-col cols="6 mb-2">
                                            <!-- <b-form-select
                        id="input-type"
                        v-model="alasan"
                        class="mb-0"
                        size="sm"
                      >
                        <option
                          v-for="opt in alasan_option"
                          :key="opt.value"
                          :value="opt.value"
                          >
                          {{ opt.value }} - {{ opt.text }}
                        </option>
                      </b-form-select> -->
                                            <v-select v-model="alasan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="label" :reduce="(option) => option.id" :options="t_alasan_list" class="select-size-sm" />
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </b-card>
                        </b-col>

                        <b-col class="col-xl-5">
                            <b-card>
                                <b-row class="">
                                    <b-col sm="6">
                                        <label for="textarea-default" class="font-weight-bolder">Hasil Penyandian</label>
                                    </b-col>
                                </b-row>
                                <b-row class="">
                                    <b-col sm="12">
                                        <!-- <b-form-textarea id="textarea-default" class="font-weight-bolder" placeholder="Sandi" rows="17" v-model="sandi_pibal" readonly style="color: black"></b-form-textarea> -->
                                        <div class="p-1" style="background-color: #cfcece; border: 2px solid #6e6b7b; border-radius: 5px;">
                                            <b-form-textarea
                                                v-if="arr_sandi_pibal.length > 0"
                                                class="p-1 mt-1 mb-1 textsandi"
                                                style="background-color: #cfcece; border: 2px solid #6e6b7b; border-radius: 5px; color: black;"
                                                readonly
                                                rows="4"
                                                v-model="arr_sandi_pibal[0]"
                                            >
                                                <!-- {{arr_sandi_pibal[0]}} -->
                                            </b-form-textarea>
                                            <b-form-textarea
                                                v-if="arr_sandi_pibal.length > 1"
                                                class="p-1 mb-1 textsandi"
                                                style="background-color: #cfcece; border: 2px solid #6e6b7b; border-radius: 5px; color: black;"
                                                readonly
                                                rows="4"
                                                v-model="arr_sandi_pibal[1]"
                                            >
                                                <!-- {{arr_sandi_pibal[1]}} -->
                                            </b-form-textarea>
                                            <b-form-textarea
                                                v-if="arr_sandi_pibal.length > 2"
                                                class="p-1 mb-1 textsandi"
                                                style="background-color: #cfcece; border: 2px solid #6e6b7b; border-radius: 5px; color: black;"
                                                readonly
                                                rows="4"
                                                v-model="arr_sandi_pibal[2]"
                                            >
                                                <!-- {{arr_sandi_pibal[2]}} -->
                                            </b-form-textarea>
                                            <b-form-textarea
                                                v-if="arr_sandi_pibal.length > 3"
                                                class="p-1 mb-1 textsandi"
                                                style="background-color: #cfcece; border: 2px solid #6e6b7b; border-radius: 5px; color: black;"
                                                readonly
                                                rows="4"
                                                v-model="arr_sandi_pibal[3]"
                                            >
                                                <!-- {{arr_sandi_pibal[3]}} -->
                                            </b-form-textarea>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <b-col sm="12" class="btn-group-submit">
                                        <!-- <div class="btn-group-submit">
                          <b-button variant="warning" class=" mr-2" size="sm"
                            ><b-icon icon="eye-fill"></b-icon> Sandi</b-button>
                            <b-button variant="primary" size="sm"><b-icon icon="cursor-fill"></b-icon> Send</b-button>
                          </div> -->
                                        <div class="btn-group-submit">
                                            <b-button variant="warning" class="mr-2" size="sm" @click="setSandiPibal()">Sandi</b-button>
                                            <!-- <b-button variant="primary" class="mr-2" size="sm" @click="submitPibal()">
                      Send
                    </b-button> -->
                                            <b-button id="submit_edit" variant="primary" class="mr-2" size="sm" @click.prevent="validationForm" v-if="is_data_exist" :disabled="!roles.isRoleEdit">
                                                Submit
                                            </b-button>

                                            <b-button id="submit_new" variant="primary" class="mr-2" size="sm" @click.prevent="validationForm" v-if="!is_data_exist" :disabled="!roles.isRoleAdd">
                                                Submit
                                            </b-button>

                                            <b-button variant="primary" class="mr-1 float-right" size="sm" :disabled="!arr_sandi_pibal || (arr_sandi_pibal && arr_sandi_pibal.length == 0)" @click="sendGtsMessage()">
                                                Send
                                            </b-button>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
                </b-form>
            </b-col>
        </b-row>

        <b-modal id="send-gts-message" ref="send-gts-message" title="SEND GTS MESSAGES" button-size="md" size="lg" centered no-close-on-backdrop>
            <b-row class="mb-1">
                <b-col cols="12">
                    <!-- <b-form-textarea id="textarea-sandi-gts" 
            placeholder="Sandi" 
            v-model="sandi_gts_message" 
            class="font-weight-bolder"
            rows="9">
          </b-form-textarea> -->
                    <div class="p-1" style="background-color: #cfcece; border: 2px solid #6e6b7b; border-radius: 5px;">
                        <b-form-textarea v-if="arr_sandi_pibal.length > 0" class="p-1 mt-1 mb-1 textsandi" style="border: 2px solid #6e6b7b; border-radius: 5px; color: black;" rows="4" v-model="arr_sandi_pibal[0]">
                            <!-- {{arr_sandi_pibal[0]}} -->
                        </b-form-textarea>
                        <b-form-textarea v-if="arr_sandi_pibal.length > 1" class="p-1 mb-1 textsandi" style="border: 2px solid #6e6b7b; border-radius: 5px; color: black;" rows="4" v-model="arr_sandi_pibal[1]">
                            <!-- {{arr_sandi_pibal[1]}} -->
                        </b-form-textarea>
                        <b-form-textarea v-if="arr_sandi_pibal.length > 2" class="p-1 mb-1 textsandi" style="border: 2px solid #6e6b7b; border-radius: 5px; color: black;" rows="4" v-model="arr_sandi_pibal[2]">
                            <!-- {{arr_sandi_pibal[2]}} -->
                        </b-form-textarea>
                        <b-form-textarea v-if="arr_sandi_pibal.length > 3" class="p-1 mb-1 textsandi" style="border: 2px solid #6e6b7b; border-radius: 5px; color: black;" rows="4" v-model="arr_sandi_pibal[3]">
                            <!-- {{arr_sandi_pibal[3]}} -->
                        </b-form-textarea>
                    </div>
                </b-col>
            </b-row>
            <template #modal-footer>
                <b-button size="sm" @click="onCancel">
                    Cancel
                </b-button>
                <b-button size="sm" variant="gradient-primary" @click="onSendBmkgSwitching">
                    Send to Gts Switching
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import { BRow, BCol, BTable, BCardText, BFormInput, BContainer, BButton, BCard, BFormDatepicker, BFormTextarea, BIcon, BFormSelect, BFormGroup, BForm, BButtonGroup, BOverlay, VBTooltip, BTooltip, BModal } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import metadata from "@/api/metadata";
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
import { GC_PIBAL_ARAH_ANGIN_PERMUKAAN, GC_PIBAL_KEC_ANGIN_PERMUKAAN, GC_ELEVASI_STATION, GC_AZIMUT, GC_ELEVASI, RC_PIBAL_AZIMUT, GC_PIBAL } from "@/validators/pibalvalidator";
import Helper from "@/api/helper";
import vSelect from "vue-select";
import Service from "@/api/pibalservice";
import useJwt from "@/auth/jwt/useJwt";
import axios from "axios";
// import FormRadioContextualStatesVue from "@/views/forms/form-element/form-radio/FormRadioContextualStates.vue";

export default {
    components: {
        VueAutosuggest,
        BRow,
        BCol,
        BTable,
        BCardText,
        BFormInput,
        BContainer,
        BButton,
        BCard,
        BFormDatepicker,
        BFormTextarea,
        BIcon,
        BFormSelect,
        BFormGroup,
        BForm,
        BButtonGroup,
        BOverlay,
        ToastificationContent,
        vSelect,
        VBTooltip,
        BTooltip,
        BModal,
    },
    mixins: [validationMixin],

    props: ["roles"],
    data: function () {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const maxDate = new Date(today);
        return {
            formShowed: false,
            firstReadingMessage: "",
            pibal_time: {
                is_pibal_00: false,
                is_pibal_06: false,
                is_pibal_12: false,
                is_pibal_18: false,
            },
            isSetSandi: false,
            max: maxDate,
            list_observer: [],
            t_observer: "",
            is_data_exist: false,
            isRangeCheck: false,
            t_station: "",
            t_station_path_selected: null,
            t_station_list: [],
            t_path_list: [],
            t_wmoid: "",
            t_wmoid_list: [],
            t_path: "",
            t_elevation_list: [],
            // wmo_id: "96001",
            tinggi_balon_count: 0,
            minNumPembacaan: 10,
            WIN_DIR_SURFACE: "",
            WIN_SPEED_SURFACE: "",
            ALAT_PIBAL: "",
            t_alat_list: [
                { id: "0", label: "0 - Alat pengukur tekanan bercampur dengan alat pengukur angin" },
                { id: "1", label: "1 - Optical Theodolite" },
                { id: "2", label: "2 - Radio Theodolite" },
                { id: "3", label: "3 - Radar" },
                { id: "4", label: "4 - Alat pengukur tekanan bercampur dengan alat pengukur angin tetapi pengukur tekanan gagal pada saat pengamatan" },
            ],

            // AZIMUT: [],
            // ELEVASI: [],
            ELEVASI_STATION: 49.2,
            TINGGI_BALON: [],
            LEVEL: [],
            WD: [],
            WS: [],
            dddff: [],
            dn: [],
            yn: [],
            xn: [],
            deltaY: [],
            deltaX: [],
            ff: [],
            datawmoid: [],
            filteredwmo: [],
            datasuggest: [],
            filteredOptions: [],
            limit: 10,
            alasan: "RA",
            // alasan_option: [
            //   { id: "RA", label: "RA(Hujan)" },
            //   { id: "MLC", label: "MLC(Awan Rendah)" },
            //   { id: "Hz", label: "Hz(Kabut)" },
            //   { id: "BB", label: "BB(Balon Pecah)" },
            //   { id: "GA", label: "GA(Masalah Alat)" },
            //   { id: "LL", label: "Lain - Lain" },
            // ],
            t_alasan_list: [
                { id: "RA", label: "RA (Hujan)" },
                { id: "MLC", label: "MLC (Awan Rendah)" },
                { id: "Hz", label: "Hz (Kabut)" },
                { id: "BB", label: "BB (Balon Pecah)" },
                { id: "GA", label: "GA (Masalah Alat)" },
                { id: "LIC", label: "LIC (Lost In Cloud)" },
                { id: "LL", label: "Lain - Lain" },
            ],
            jam: "",
            t_jam_list: ["", "00:00", "06:00", "12:00", "18:00"],
            tanggal: "",
            sandi_pibal: "",
            sandi_pibal_with_header: "",
            arr_sandi_pibal: [],
            PEMBACAAN: [
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
            ],
            BOARD_TEST_2: [],
            BOARD: [],
            gts_message: {
                is_send_to_gts_switching: 0,
                header_ppaa: "",
                header_ppab: "",
                header_ppac: "",
                header_ppad: "",
                header_bilateral: "",
            },
            showLoading: false,
            isEdit: false,
        };
    },
    validations: {
        t_observer: { required },
        WIN_DIR_SURFACE: { required, GC_PIBAL_ARAH_ANGIN_PERMUKAAN, between: between(0, 360) },
        WIN_SPEED_SURFACE: { required, GC_PIBAL_KEC_ANGIN_PERMUKAAN, between: between(0, 60) },
        ELEVASI_STATION: { required, GC_ELEVASI_STATION },
        ALAT_PIBAL: { required },
        PEMBACAAN: {
            $each: {
                azimut: { required, GC_AZIMUT },
                elevasi: { required, GC_ELEVASI },
            },
        },
    },

    methods: {
        onKeyCheck(event) {
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                event.preventDefault();
            }
        },
        isNumber(event) {
            if (event.key == ".") {
                return event.preventDefault();
            }
        },
        format_one_decimal(e) {
            if (Math.round(e * 10) / 10 != e) {
                let text = String(e);
                let filter = text.substring(0, text.length - 1);
                return filter;
            } else {
                if (Math.floor(e) === e) {
                    return e;
                } else {
                    if (e.toString().indexOf(".") > 0) {
                        let count = e.toString().split(".")[1].length || 0;
                        return count > 1 ? parseFloat(e).toFixed(1) : e;
                    } else {
                        return e;
                    }
                }
                return e;
            }
        },
        checkValidate: function (event, type) {
            // console.log("event", event.target.value);
            let value = event.target.value;

            switch (type) {
                case "input-arah":
                    var raw = RC_PIBAL_AZIMUT(value);
                    // console.log("raw", raw);
                    if (!raw.status) {
                        this.isRangeCheck = true;
                    } else {
                        this.isRangeCheck = false;
                    }
                    break;
                default:
                    break;
            }
        },
        validateState(item) {
            const { $dirty, $error } = item;
            return $dirty ? !$error : null;
        },
        validateTooltipState(name) {
            const { $error } = name;
            return $error;
        },
        validationForm() {
            this.$v.$reset();
            // this.$v.t_observer.$touch();
            // this.$v.WIN_DIR_SURFACE.$touch();
            // this.$v.WIN_SPEED_SURFACE.$touch();
            // this.$v.ELEVASI_STATION.$touch();
            // this.$v.PEMBACAAN.$touch();
            // this.$v.ALAT_PIBAL.$touch();
            // if (this.$v.t_observer.$anyError || this.$v.WIN_DIR_SURFACE.$anyError || this.$v.WIN_SPEED_SURFACE.$anyError || this.$v.ELEVASI_STATION.$anyError || this.$v.PEMBACAAN.$anyError || this.$v.ALAT_PIBAL.$anyError) {
            //   let position = "top-center";
            //   this.$toast(
            //     {
            //       component: ToastificationContent,
            //       props: {
            //         title: "Data tidak valid. Periksa kembali inputan anda",
            //         icon: "BellIcon",
            //         variant: "danger",
            //       },
            //     },
            //     {
            //       position,
            //     }
            //   );
            // } else {
            //   this.submitPibal();
            // }

            this.$v.$touch();

            if (this.$v.$invalid) {
                let position = "top-center";
                this.$toast(
                    {
                        component: ToastificationContent,
                        props: {
                            title: "Data tidak valid. Periksa kembali inputan anda",
                            icon: "BellIcon",
                            variant: "danger",
                        },
                    },
                    { position }
                );
            } else {
                this.submitPibal();
            }
        },

        tooltipMessage(attr) {
            return GC_PIBAL(attr).message;
        },

        padZero(val, n) {
            return `${"0".repeat(n)}${val}`.substr(-n, n);
        },

        onCancel() {
            this.$refs["send-gts-message"].hide();
        },

        sendGtsMessage() {
            let header = 2; // (this.metar_speci_type !== "SPECI") ? this.metarspeci_gts_metar_header : this.metarspeci_gts_speci_header

            if (this.gts_message.is_send_to_gts_switching && this.gts_message.is_send_to_gts_switching == 1 && this.gts_message.header_ppaa && this.gts_message.header_ppaa != "") {
                // this.sandi_gts_message = this.sandi_with_header
                this.$refs["send-gts-message"].show();
            } else {
                let title = "";
                let message = "";
                if (this.gts_message.is_send_to_gts_switching == 0) {
                    title = "Anda tidak memiliki ijin untuk mengirim ke GTS Switching";
                    message = "Periksa metadata stasiun atau hubungi admin anda.";
                } else {
                    title = "Header Tidak Ada / Tidak Ditemukan";
                    message = "Header belum ada pada metadata.";
                }
                this.$swal({
                    title: title,
                    text: message,
                    icon: "danger",
                    customClass: {
                        confirmButton: "btn btn-danger",
                    },
                    buttonsStyling: false,
                });
                return;
            }
        },

        onSendBmkgSwitching() {
            this.submitSendBmkgSwitching();
        },

        async submitSendBmkgSwitching() {
            let noId = 2;

            let mdate = new Date();
            let id =
                "" +
                this.padZero(noId, 2) +
                this.t_wmoid +
                mdate.getFullYear() +
                this.padZero(mdate.getMonth() + 1, 2) +
                this.padZero(mdate.getDate(), 2) +
                this.padZero(mdate.getHours(), 2) +
                this.padZero(mdate.getMinutes(), 2) +
                this.padZero(mdate.getSeconds(), 2);
            // console.log("submitSendBmkgSwitching - id", id, this.t_station)

            let tmp_sandi = this.gts_message.header_ppaa.split(/[,\s]+/);
            // console.log('tmp_sandi', tmp_sandi)
            const post_data = {
                "@type": "GTSMessage",
                id: id,
                type_message: noId,
                sandi_gts: this.sandi_pibal_with_header,
                sandi_pibal: this.arr_sandi_pibal,
                // "timestamp_data": this.tanggal + "T" + this.jam,
                timestamp_sent_data:
                    mdate.getFullYear() +
                    "-" +
                    this.padZero(mdate.getUTCMonth() + 1, 2) +
                    "-" +
                    this.padZero(mdate.getUTCDate(), 2) +
                    "T" +
                    this.padZero(mdate.getUTCHours(), 2) +
                    ":" +
                    this.padZero(mdate.getUTCMinutes(), 2) +
                    ":" +
                    this.padZero(mdate.getUTCSeconds(), 2),
                timestamp_data: this.tanggal + "T" + this.jam,
                wmoid: this.t_wmoid,
                ttaaii: tmp_sandi.length > 0 ? tmp_sandi[0] : "",
                cccc: tmp_sandi.length > 1 ? tmp_sandi[1] : "",
            };

            let url_gts = Helper.getBaseUrl() + this.t_station_path_selected + "/gts";
            // console.log('post_data', post_data, url_gts)
            this.showLoading = true;
            await axios
                .post(url_gts, post_data, Helper.getConfig())
                .then((response) => {
                    if (response.status == 201 || response.status == 200 || response.status == 204) {
                        this.$swal({
                            title: "Input Data GTS Messages Berhasil",
                            text: "Input Data GTS Message",
                            icon: "success",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        this.showLoading = false;
                        this.$refs["send-gts-message"].hide();
                    }
                    this.showLoading = false;
                })
                .catch((error) => {
                    this.showLoading = false;
                    // error_handler(this, error, "Sinoptik", "Input Data Error");
                    // console.log('error', error, error.response.status, )
                    if (error.response.status == 409) {
                        // this.updateFormComfirm(id, post_data)
                    } else {
                    }
                });

            // this.clearData()
            // this.showLoading = false
        },

        // gross_check_tooltip(param) {
        //   // console.log('consistency_check_tooltip', param)
        //   switch (param) {
        //     case "WIN_DIR_SURFACE":
        //       if (this.WIN_DIR_SURFACE >= 0 && this.WIN_DIR_SURFACE < 360 ) {
        //         let result = { status: true, message: "Suhu Bola Kering >= Suhu Bola Basah" };
        //         return result;
        //       }
        //       return { status: false, message: "" };
        //     case "WIN_SPEED_SURFACE":
        //       if (this.WIN_SPEED_SURFACE >= 0 && this.WIN_SPEED_SURFACE < 60) {
        //         return { status: true, message: "Suhu Bola Kering >= Suhu Bola Basah" };
        //       }
        //       return { status: false, message: "" };

        //     default:
        //       return { status: false, message: "" };
        //   }
        // },

        onTab(event, index) {
            // console.log('onTab', index)

            if (index == this.PEMBACAAN.length - 1) {
                this.minNumPembacaan = this.minNumPembacaan + 1;
                this.PEMBACAAN.push({
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                });
                this.showLevel();
            }
        },

        formatWindDir(e) {
            return String(e).substring(0, 3);
        },
        formatWindSpeed(e) {
            return String(e).substring(0, 2);
        },
        formatAzimut(e) {
            if (String(e).includes(".")) {
                var fix = Number.parseFloat(e).toFixed(1);
                return String(fix).substring(0, 5);
            } else return String(e).substring(0, 5);
        },
        formatElevasi(e) {
            if (String(e).includes(".")) {
                var fix = Number.parseFloat(e).toFixed(1);
                return String(fix).substring(0, 4);
            } else return String(e).substring(0, 4);
        },
        // autosuggest observer
        onInputChange(text) {
            if (text === "" || text === undefined) {
                return;
            }

            this.datasuggest = [
                {
                    observers: ["113101-John", "113402-Sari", "113003-Darwin", "111001-Shelly", "153042-Gorge", "123042-Maya", "113142-Dara", "115112-Ahmad"],
                },
            ];

            const filteredObservers = this.datasuggest[0].observers.filter((item) => item.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit);
            const filteredData = filteredObservers;
            this.filteredOptions = [
                {
                    data: filteredData,
                },
            ];
        },
        onwmoidChange(text) {
            if (text === "" || text === undefined) {
                return;
            }

            this.wmoidsuggest = [
                {
                    observers: ["113101", "113402", "113003", "111001", "153042", "123042", "113142", "115112"],
                },
            ];

            const filteredwmoid = this.wmoidsuggest[0].observers.filter((item) => item.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit);
            const filteredData = filteredwmoid;
            this.filteredwmo = [
                {
                    data: filteredData,
                },
            ];
        },
        addPembacaan() {
            // this.minNumPembacaan = this.minNumPembacaan + 2;
            // this.PEMBACAAN.push({ azimut: "", elevasi: "", tinggi_balon: 0, dn: 0, xn: 0, yn: 0, st_hitung: false });
            // this.PEMBACAAN.push({ azimut: "", elevasi: "", tinggi_balon: 0, dn: 0, xn: 0, yn: 0, st_hitung: false });
            this.minNumPembacaan = this.minNumPembacaan + 1;
            this.PEMBACAAN.push({
                azimut: "",
                elevasi: "",
                tinggi_balon: 0,
                dn: 0,
                xn: 0,
                yn: 0,
                st_hitung: false,
            });
            this.showLevel();
        },

        deletePembacaan() {
            // console.log("deletePembacaan", this.minNumPembacaan);
            // if (this.minNumPembacaan <= 1) {
            if (this.minNumPembacaan <= 0) {
                this.minNumPembacaan = 0;
                return false;
            } else {
                this.minNumPembacaan = this.minNumPembacaan - 1;
                this.PEMBACAAN.pop();
            }
            this.showLevel();
            return true;
        },
        editPembacaan() {
            // alert("test :" + this.PEMBACAAN);
        },
        combineArrayToObj(arrays) {
            // input [ [arr1, name1], [arr2, name2], ... ]
            // output { 1: { name1: val1, name2: val2, ... }, ... }
            const res = {};
            for (let i = 0; i < arrays[0][0].length; i++) {
                const temp = {};
                for (let j = 0; j < arrays.length; j++) {
                    temp[arrays[j][1]] = arrays[j][0]?.[i];
                }
                res[i + 1] = temp;
            }
            return res;
        },
        clearData() {
            // console.log('clear data')
            this.formShowed = false;
            this.isSetSandi = false;
            this.tinggi_balon_count = 0;
            this.minNumPembacaan = 10;
            this.ALAT_PIBAL = "";
            // this.ELEVASI_STATION = 49.2
            this.WD = [];
            this.WS = [];
            this.AZIMUT = [];
            this.ELEVASI = [];
            this.dddfff = [];
            this.WIN_DIR_SURFACE = "";
            this.WIN_SPEED_SURFACE = "";
            this.PEMBACAAN = [
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
                {
                    azimut: "",
                    elevasi: "",
                    tinggi_balon: 0,
                    dn: 0,
                    xn: 0,
                    yn: 0,
                    st_hitung: false,
                },
            ];
            this.BOARD = [];
            this.BOARD_TEST_2 = [];

            this.sandi_pibal = "";
            this.arr_sandi_pibal = [];
            this.sandi_pibal_with_header = "";
            this.showLoading = false;
            this.showLevel();

            this.$v.$reset();
        },
        submitPibal() {
            let tAzimut = [];
            let tElevasi = [];
            for (let i = 0; i < this.PEMBACAAN.length; i++) {
                tAzimut.push(this.PEMBACAAN[i].azimut);
                tElevasi.push(this.PEMBACAAN[i].elevasi);
            }

            const azimuth_elevasi = this.combineArrayToObj([
                [tAzimut, "azimuth"],
                [tElevasi, "elevasi"],
            ]);

            // console.log('azimuth_elevasi', azimuth_elevasi);

            const lapisan_wd_ws_dddff = this.combineArrayToObj([
                [this.WD, "wd"],
                [this.WS, "ws"],
                [this.LEVEL, "lapisan"],
                [this.dddff, "dddff"],
            ]);

            // console.log('this.list_observer', this.list_observer)
            let ido = this.t_observer;
            let observer_name = "";
            if (this.t_observer && this.t_observer != "") {
                observer_name = this.list_observer.filter(function (el) {
                    return el.id == ido;
                })[0].observer;
            }
            // console.log('t_observer', this.t_observer, observer_name);
            // console.log(lapisan_wd_ws_dddff);
            // const id_pibal = this.wmo_id+"_pibal_"+this.date+"T"+this.jam
            const id_pibal = this.t_station + "_pibal_" + this.tanggal + "T" + this.jam.substr(0, 2);
            const data_timestamp = this.tanggal + "T" + this.jam;

            const post_data = {
                "@type": "Pibal",
                station_elevation: this.ELEVASI_STATION,
                wind_dir_surface: this.WIN_DIR_SURFACE,
                wind_speed_surface: this.WIN_SPEED_SURFACE,
                source: "Entry Pibal",
                sandi_pibal: this.sandi_pibal,
                sandi_pibal_with_header: this.sandi_pibal_with_header,
                sandi_pibal_with_header_array: this.arr_sandi_pibal,
                pembacaan_azimuth_elevasi: azimuth_elevasi,
                hasil_lapisan_wd_ws_dddff: lapisan_wd_ws_dddff,
                data_timestamp: data_timestamp,
                penghentian_pengamatan: this.alasan,
                station_id: this.t_station,
                a4: this.ALAT_PIBAL,
                id: id_pibal,
                observer_id: this.t_observer,
                observer_name: observer_name,
            };
            // station_elevation: parseFloat(this.ELEVASI_STATION),
            // console.log("post_data", post_data);
            const station_id = "1";
            const url_pibal = Helper.getBaseUrl() + "station/" + station_id + "/meteorologi/pibal";

            this.showLoading = true;
            // var tid = this.t_station+"_pibal_"+this.tanggal + "T" + this.jam.substr(0, 2);
            var path = this.t_path; //+"/"+tid
            // console.log("isEdit: ", this.isEdit);
            if (!this.isEdit) {
                path = this.t_path;

                Service.submitNewPibal(post_data, path)
                    .then((response) => {
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            this.clearData();
                            this.jam = "";
                            this.$swal({
                                title: "Input Data Berhasil!",
                                text: "Input Data Pibal",
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                            this.showLevel();
                        }
                    })
                    .catch((error) => {
                        // console.log("error.status: ", error.response);
                        if (error.response.status == 401) {
                            this.$swal({
                                title: "Gagal entri!",
                                text: "Sesi anda telah habis. Logout dan silahkan login kembali",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else if (error.response.status == 404) {
                            this.$swal({
                                title: "Gagal entri!",
                                text: "Data tidak ditemukan",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else {
                            // alert(" Data is duplicated ");
                            this.$swal({
                                title: "Entri Error!",
                                text: "Input Data Pibal Sudah ada",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }
                        this.showLoading = false;
                    });
            } else {
                var tid = this.t_station + "_pibal_" + this.tanggal + "T" + this.jam.substr(0, 2);
                path = this.t_path + "/" + tid;

                Service.editPibal(post_data, path)
                    .then((response) => {
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            this.clearData();
                            this.jam = "";
                            this.$swal({
                                title: "Edit data berhasil!",
                                text: "Input Data Pibal",
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                            this.showLevel();
                        }
                    })
                    .catch((error) => {
                        // console.log("error.status: ", error.response);
                        if (error.response.status == 401) {
                            this.$swal({
                                title: "Gagal entry!",
                                text: "Sesi anda telah habis. Logout dan silahkan login kembali",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else if (error.response.status == 404) {
                            this.$swal({
                                title: "Gagal entry!",
                                text: error.response.data.reason,
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else {
                            // alert(" Data is duplicated ");
                            this.$swal({
                                title: "Entri Error!",
                                text: "Terjadi kesalahan.",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }
                        this.showLoading = false;
                    });
            }
        },
        showAlert(object) {
            let position = "top-center";
            this.$toast(
                {
                    component: ToastificationContent,
                    props: {
                        title: "Data " + object + " belum diisi",
                        icon: "BellIcon",
                        variant: "danger",
                    },
                },
                {
                    position,
                }
            );
        },
        showAlertPopupWithMessage(message) {
            let position = "top-center";
            this.$toast(
                {
                    component: ToastificationContent,
                    props: {
                        title: message,
                        icon: "BellIcon",
                        variant: "danger",
                    },
                },
                {
                    position,
                }
            );
        },
        updatePembacaan() {
            this.dn = [];
            this.WS = [];
            this.WD = [];
            this.dddff = [];
            this.BOARD = [];

            for (var n = 0; n < this.PEMBACAAN.length; n++) {
                let pembacaan = n + 1;

                if (this.PEMBACAAN[n].st_hitung) {
                    var t_dn = ((2 * pembacaan - 1) * 250 * this.COTG(this.PEMBACAAN[n].elevasi)) / 202.67;
                    this.PEMBACAAN[n].dn = t_dn;

                    var y1 = t_dn * this.COS(this.PEMBACAAN[n].azimut);
                    this.PEMBACAAN[n].yn = y1;

                    var x1 = t_dn * this.SIN(this.PEMBACAAN[n].azimut);
                    this.PEMBACAAN[n].xn = x1;

                    this.dn.push(t_dn);
                } else {
                    this.dn.push(-1);
                }
            }

            let iLevel = 0;
            if (this.PEMBACAAN.length > 0 && !this.PEMBACAAN[0].st_hitung) {
                iLevel = 1;
                this.WS.push(-1);
                this.WD.push(-1);
                this.dddff.push(-1);
            }
            for (var i = 0; i < this.PEMBACAAN.length; i++) {
                if (this.PEMBACAAN[i].st_hitung) {
                    if (i >= this.PEMBACAAN.length - 1) {
                        break;
                    }

                    let az1 = this.PEMBACAAN[i].azimut;
                    let el1 = this.PEMBACAAN[i].elevasi;
                    let az2 = this.PEMBACAAN[i + 1].azimut;
                    let el2 = this.PEMBACAAN[i + 1].elevasi;

                    var y1 = this.PEMBACAAN[i].yn;
                    var y2 = this.PEMBACAAN[i + 1].yn;
                    var t_deltaY = y1 - y2;

                    var x1 = this.PEMBACAAN[i].xn;
                    var x2 = this.PEMBACAAN[i + 1].xn;
                    var t_deltaX = x1 - x2;

                    var ff = Math.round(Math.sqrt(t_deltaX * t_deltaX + t_deltaY * t_deltaY) * 2);
                    if (az1 == 9999 || el1 == 9999 || az2 == 9999 || el2 == 9999) {
                        this.WS.push(-999);
                    } else {
                        this.WS.push(ff);
                    }

                    var alpa = Math.atan(Math.abs(t_deltaY) / Math.abs(t_deltaX)) * (180 / Math.PI);

                    var dd = 0;
                    if (t_deltaX >= 0 && t_deltaY > 0) {
                        dd = 90 - parseFloat(alpa);
                    } else if (t_deltaX > 0 && t_deltaY <= 0) {
                        dd = 90 + parseFloat(alpa);
                    } else if (t_deltaX <= 0 && t_deltaY < 0) {
                        dd = 270 - parseFloat(alpa);
                    } else {
                        dd = 270 + parseFloat(alpa);
                    }
                    
                    let kDD = Math.round(dd);
                    let kelipatanDD = Math.round(dd / 5) * 5;

                    // AN
                    if (ff < 1) {
                        kelipatanDD = 0
                    }
                    else if (kelipatanDD === 0 && ff >= 1) {
                        kelipatanDD = 360
                    }

                    if (az1 == 9999 || el1 == 9999 || az2 == 9999 || el2 == 9999) {
                        this.WD.push(-999);
                    } else {
                        this.WD.push(kelipatanDD);
                    }

                    var dddff = this.combineDirSpeed(kelipatanDD, ff);
                    if (az1 == 9999 || el1 == 9999 || az2 == 9999 || el2 == 9999) {
                        dddff = "/////";
                    }

                    this.dddff.push(dddff);

                    this.BOARD.push({
                        level: this.LEVEL[iLevel],
                        ddd: kelipatanDD,
                        ff: ff,
                        dddff: dddff,
                        sl: false,
                        slff: false,
                    });

                    i++;
                    iLevel++;
                }
            }
        },
        combineDirSpeed(ddd, ff) {
            var dddff = "";
            var ff_len = String(ff);
            // var dd_len = String(parseInt(dd));
            var dd_len = String(ddd);
            // // console.log('WD: '+this.WD)
            // console.log("leng ff " + ff_len);
            if (ff_len.length == 1) {
                if (dd_len.length == 1) {
                    dddff = "00" + dd_len + "0" + ff_len;
                } else if (dd_len.length == 2) {
                    dddff = "0" + dd_len + "0" + ff_len;
                } else {
                    dddff = dd_len + "0" + ff_len;
                }
            } else if (ff_len.length == 2) {
                if (dd_len.length == 1) {
                    dddff = "00" + dd_len + ff_len;
                } else if (dd_len.length == 2) {
                    dddff = "0" + dd_len + ff_len;
                } else {
                    dddff = dd_len + ff_len;
                }
            } else {
                if (dd_len.length == 1) {
                    dddff = "0" + dd_len + ff_len;
                } else if (dd_len.length == 2) {
                    dddff = dd_len + ff_len;
                } else {
                    var dd_log = dd_len.substring(0, 2);
                    dddff = dd_log + ff_len;
                }
            }
            // console.log("dddfff : " + dddff);

            return dddff;
        },
        normalisasiLevel(level) {
            let strLevel = String(level);
            if (strLevel.length == 1) {
                return "000" + strLevel;
            } else if (strLevel.length == 2) {
                return "00" + strLevel;
            } else if (strLevel.length == 3) {
                return "0" + strLevel;
            } else {
                return level;
            }
        },
        // nextPoint(pt1)  {
        //   for (var i = 0 ; i < this.BOARD_TEST_2.length; i++) {
        //     if (pt1.level != this.BOARD_TEST_2[i].level) {
        //       if (this.BOARD_TEST_2[i].sl) {
        //         return this.BOARD_TEST_2[i]
        //       }
        //     }
        //   }
        //   return null
        // },
        titikY(x1, y1, x2, y2, x) {
            return ((x - x1) * (y2 - y1)) / (x2 - x1) - y1;
        },
        titikX(x1, y1, x2, y2, y) {
            // console.log('x1:'+x1+', y1:'+y1+', x2:'+x2+', y2:'+y2+', y:'+y)
            // console.log('(y - y1) * (x2 - x1)', (y - y1) * (x2 - x1))
            return ((y - y1) * (x2 - x1)) / (y2 - y1) + parseFloat(x1);
        },
        nextSignificant(currSigIndex, type) {
            for (var i = currSigIndex; i < this.BOARD_TEST_2.length; i++) {
                if (i != currSigIndex) {
                    if (this.BOARD_TEST_2[i][type]) {
                        // console.log('nextSignificant', type,   this.BOARD_TEST_2[i][type])
                        return i;
                    }
                }
            }
            return null;
        },
        checkCandidateSignificant(idxSignf1, idxSignf2, type) {
            // console.log('checkCandidateSignificant : ', idxSignf1+'-'+idxSignf2+' -> '+type)
            let pptype = type == "sl" ? "ddd" : "ff";
            let sig1 = this.BOARD_TEST_2[idxSignf1];
            let sig2 = this.BOARD_TEST_2[idxSignf2];
            let candSignf = null;
            let xCandSignf = null;
            let JT = null;
            let oldJT = null;
            let startIdx = idxSignf1 + 1;
            // console.log("cand sl - level ", pptype + ' : ' + sig1.level + " --> " + sig2.level);
            // console.log('sig1: ', sig1)
            // console.log('sig2: ', sig2)
            for (var i = startIdx; i < idxSignf2; i++) {
                let lapisanN = this.BOARD_TEST_2[i];
                let yCn = lapisanN.level;
                let xCn = this.titikX(sig1[pptype], sig1.level, sig2[pptype], sig2.level, yCn);

                JT = Math.abs(xCn - lapisanN[pptype]);
                // console.log('xCn : ', i, xCn, pptype, lapisanN[pptype] )
                // console.log('JT : ', i, JT )
                // console.log('oldJT : ', i, oldJT )

                if (!oldJT) {
                    // == null
                    if (JT >= 10) {
                        xCandSignf = xCn;
                        candSignf = lapisanN;
                        oldJT = JT;
                    }
                } else {
                    if (JT > oldJT && JT >= 10) {
                        xCandSignf = xCn;
                        candSignf = lapisanN;
                    }
                }

                // if(candSignf == null) {
                // 	xCandSignf = xCn;
                // 	candSignf = lapisanN;
                // 	JT = Math.abs(xCandSignf - candSignf[pptype]);
                // }
                // else {
                // 	if(xCn > xCandSignf) {
                // 		xCandSignf = xCn;
                // 		candSignf = lapisanN;
                // 		JT = Math.abs(xCandSignf - candSignf[pptype]);
                // 	}
                // }

                // console.log("LJT: ", i, JT + ' : ' + xCandSignf ? xCandSignf: 'null' + ' - ' + candSignf ? candSignf[pptype] : 'null')
                // console.log("LJTa", i, JT, xCandSignf)
                // console.log("LJTb", i,  candSignf, candSignf ? candSignf[pptype] : null)
            }

            // if (candSignf && JT > oldJT && JT >= 10) {
            // 	console.log("signifikan baru : ",candSignf[pptype] + ", " + candSignf.level + " ==> "+JT);
            // 	candSignf[type] = true;
            // } else {
            // 	candSignf = null;
            // }

            if (candSignf) {
                // console.log("signifikan baru : ",candSignf, candSignf[pptype] + ", " + candSignf.level + " ==> "+JT);
                candSignf[type] = true;
                candSignf["sl"] = true;
                candSignf["slff"] = true;
            }

            return candSignf;
        },
        checkSignificantLevel(type) {
            /**
             * masukkan elevasi stasiun sebagai level permukaan
             */

            /**
             * level yg pertama = level permukaan = significant pertama
             * level yg terakhir = significant terakhir
             * level 35000 = lapisan tertinggi dari pengamatan
             * Significant Level lapisan REGIONAL
             * */
            // console.log("checkSignificantLevel : ", type);
            // console.log("BOARD_TEST_2 SL sebelum : ", this.BOARD_TEST_2);
            let isLessTenThousandFeet = true;
            for (var i = 0; i < this.BOARD_TEST_2.length; i++) {
                if (this.BOARD_TEST_2[i].level >= 10000) {
                    isLessTenThousandFeet = false;
                    break;
                }
            }

            for (var i = 0; i < this.BOARD_TEST_2.length; i++) {
                if (isLessTenThousandFeet) {
                    this.BOARD_TEST_2[i][type] = true;
                } else {
                    if (
                        i == 0 ||
                        i == this.BOARD_TEST_2.length - 1 ||
                        this.BOARD_TEST_2[i].level == 1000 || // issue: PERKA BARU > tahun 2024
                        this.BOARD_TEST_2[i].level == 3000 ||
                        this.BOARD_TEST_2[i].level == 7000 ||
                        this.BOARD_TEST_2[i].level == 14000
                        // || this.BOARD_TEST_2[i].level == 35000
                    ) {
                        this.BOARD_TEST_2[i][type] = true;
                    }
                }
            }

            console.log("BOARD_TEST_2 SL Regional : ", this.BOARD_TEST_2);

            for (var i = 0; i < this.BOARD_TEST_2.length - 1; i++) {
                let idxSig1 = null;
                let idxSig2 = null;

                if (this.BOARD_TEST_2[i][type]) {
                    let isDDDSignf1 = false;
                    let isFFSignf1 = false;
                    idxSig1 = i;
                    idxSig2 = this.nextSignificant(idxSig1, type);

                    isDDDSignf1 = this.checkCandidateSignificant(idxSig1, idxSig2, type) ? true : false;
                    // console.log('isDDDSignf1', isDDDSignf1)
                    if (!isDDDSignf1) {
                        isFFSignf1 = this.checkCandidateSignificant(idxSig1, idxSig2, "slff") ? true : false;
                    }

                    if (isDDDSignf1 || isFFSignf1) {
                        i--;
                    }
                }
            }

            // console.log("BOARD_TEST_2 SL setelah: ", this.BOARD_TEST_2);
        },

        petunjuk6() {},

        clearBoard() {
            if (this.BOARD_TEST_2 && this.BOARD_TEST_2.length > 0) {
                // console.log('clear boaard ')
                for (var i = 0; i < this.BOARD_TEST_2.length; i++) {
                    this.BOARD_TEST_2[i].sl = false;
                    this.BOARD_TEST_2[i].slff = false;
                }
                // console.log("clear boaard ", this.BOARD_TEST_2);
            }
        },

        checkInputPembacaan() {
            let isMandatoryFailed = false;
            let message = "";
            this.$v.t_observer.$touch();
            this.$v.WIN_DIR_SURFACE.$touch();
            this.$v.WIN_SPEED_SURFACE.$touch();
            this.$v.ELEVASI_STATION.$touch();
            this.$v.ALAT_PIBAL.$touch();

            // console.log('this.$v.t_observer.$anyError', this.$v.WIN_DIR_SURFACE, this.WIN_DIR_SURFACE)
            // console.log('this.BOARD SL sebelum : ', this.BOARD)

            // this.clearBoard()

            if (this.$v.t_observer.$anyError) {
                isMandatoryFailed = true;
                message = "Observer belum diisi";
            } else if (this.$v.WIN_DIR_SURFACE.$anyError) {
                // console.log('this.$v.WIN_DIR_SURFACE.$anyError', this.$v.WIN_DIR_SURFACE, this.WIN_DIR_SURFACE)
                isMandatoryFailed = true;
                message = "Arah Angin Permukaan belum diisi";
            } else if (this.$v.WIN_SPEED_SURFACE.$anyError) {
                isMandatoryFailed = true;
                message = "Kecepatan Angin Permukaan belum diisi";
            } else if (this.$v.ELEVASI_STATION.$anyError) {
                isMandatoryFailed = true;
                message = "Elevasi belum diisi";
            } else if (this.$v.ALAT_PIBAL.$anyError) {
                isMandatoryFailed = true;
                message = "Alat Pibal belum diisi";
            }

            if (isMandatoryFailed) {
                let position = "top-center";
                this.$toast(
                    {
                        component: ToastificationContent,
                        props: {
                            title: message,
                            icon: "BellIcon",
                            variant: "danger",
                        },
                    },
                    { position }
                );
                return false;
            } else {
                let adaKosong = false;
                // for (let i = 0; i < this.PEMBACAAN.length; i++) {
                //   let baca = this.PEMBACAAN[i];
                //   if (baca.azimut == "" || baca.elevasi == "") {
                //     // console.log('adaKosong', baca.azimut, baca.elevasi)
                //     adaKosong = true;
                //     break;
                //   }
                // }
                for (var i in this.PEMBACAAN) {
                    let baca = this.PEMBACAAN[i];

                    if (i == 0) {
                        if (!baca.st_hitung) {
                            if (this.PEMBACAAN.length == 1) {
                                this.PEMBACAAN[i].azimut = this.PEMBACAAN[i].azimut ? this.PEMBACAAN[i].azimut : 9999;
                                this.PEMBACAAN[i].elevasi = this.PEMBACAAN[i].elevasi ? this.PEMBACAAN[i].elevasi : 9999;
                            }
                            continue;
                        }
                    } else {
                        if (baca.azimut == "" || baca.elevasi == "") {
                            // console.log('adaKosong', baca.azimut, baca.elevasi)
                            adaKosong = true;
                            break;
                        }
                    }
                }

                // console.log('adaKosong', adaKosong)

                if (adaKosong) {
                    this.$swal({
                        title: "Ada pembacaan yang nilainya masih kosong.",
                        text: "Apakah data pembacaan sudah cukup ?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Ya",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: "btn btn-primary",
                            cancelButton: "btn btn-outline-danger ml-1",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        let isBreak = false;
                        let statusDelete = true;
                        while (!isBreak) {
                            let exist = false;
                            for (let i = this.PEMBACAAN.length - 1; i >= 0; i--) {
                                let baca = this.PEMBACAAN[i];
                                if (baca.st_hitung) {
                                    if (baca.azimut == "" || baca.elevasi == "") {
                                        statusDelete = this.deletePembacaan();
                                        // console.log("statusDelete", statusDelete);
                                        exist = true;
                                        break;
                                    }
                                }
                            }
                            // console.log("exist", exist);
                            if (!exist || !statusDelete) {
                                isBreak = true;
                            }
                        }
                        this.updatePembacaan();
                        this.setSandiPibal();
                        return false;
                    });
                    return false;
                } else {
                    return true;
                }
            }
        },

        setSandiPibal() {
            // console.log("setSandiPibal");
            this.$v.$touch();
            let isCheck = this.checkInputPembacaan();
            if (!isCheck) {
                // console.log('checkInput pembacaan false')
                return;
            } else if (this.$v.$invalid) {
                let position = "top-center";
                this.$toast(
                    {
                        component: ToastificationContent,
                        props: {
                            title: "Data tidak valid. Periksa kembali inputan anda",
                            icon: "BellIcon",
                            variant: "danger",
                        },
                    },
                    { position }
                );
                return;
            } else {
                this.sandi_pibal = "";
                this.arr_sandi_pibal = [];
                this.sandi_pibal_with_header = "";
                this.BOARD_TEST_2 = [];
                this.BOARD_TEST_2AC = [...this.BOARD];
                // this.BOARD_TEST_2 = [...this.BOARD];

                this.BOARD_TEST_2 = this.BOARD.filter(function (pembacaan) {
                    return pembacaan.dddff !== "/////";
                });

                let permukaan = {
                    level: this.ELEVASI_STATION,
                    ddd: this.WIN_DIR_SURFACE,
                    ff: this.WIN_SPEED_SURFACE,
                    dddff: this.combineDirSpeed(this.WIN_DIR_SURFACE, this.WIN_SPEED_SURFACE),
                    sl: false,
                    slff: false,
                };
                this.BOARD_TEST_2.unshift(permukaan);
                this.BOARD_TEST_2AC.unshift(permukaan);
                // console.log("this.BOARD_TEST_2 : ", this.BOARD_TEST_2);
                this.clearBoard();

                this.checkSignificantLevel("sl");
                // this.checkSignificantLevel("slff");

                if (this.tanggal == "") {
                    this.showAlert("Tanggal");
                    return;
                } else if (this.jam == "") {
                    this.showAlert("Jam");
                    return;
                }

                //PPAA dari 1000 - 100 mbs ; {mb:1000, ft:200}
                //PPCC dan PPDD dari 70 - 10 mbs
                const ref_PPAA_St_Level = [
                    { mb: 850, ft: 5000 },
                    { mb: 700, ft: 10000 },
                    { mb: 500, ft: 19000 },
                    { mb: 400, ft: 25000 },
                    { mb: 300, ft: 31000 },
                    { mb: 250, ft: 36000 },
                    { mb: 200, ft: 41000 },
                    { mb: 150, ft: 47000 },
                    { mb: 100, ft: 55000 },
                ];
                const ref_PPCC_St_Level = [
                    { mb: 70, ft: 61000 },
                    { mb: 50, ft: 67000 },
                    { mb: 30, ft: 78000 },
                    { mb: 20, ft: 86000 },
                    { mb: 10, ft: 95000 },
                ];

                const ref_Regional_SL = [0, 1000, 3000, 7000, 14000];

                /* Bagian A
                 * Seksi1 : YYGGa4 ; YY = 50 + tanggal ; GG
                 *
                 */
                const a4 = this.ALAT_PIBAL;
                const YY = String(parseInt(this.tanggal.substring(this.tanggal.length - 2)) + 50);
                const GG = this.jam.split(":");
                const PPAA_1 = "PPAA " + YY + GG[0] + a4 + " " + this.t_wmoid;

                /*
                 * Seksi2 : 55nP1P1 ddfff ddfff ddfff
                 * Seksi2 : 55nPnPn ddfff ddfff ddfff
                 */
                //  console.log('Seksi 2')
                let n1 = null;
                let P1P1 = null;
                let listP1P1 = "";
                let PnPn = [];
                let nn = [];
                let listPnPn = [];

                let n2 = null;
                let P2P2 = null;
                let PPAA_2_1 = null;
                let PPAA_2_2 = null;

                let listP2P2 = "";

                let PPAA_3 = "77999";
                let ppaa2List = [];
                ref_PPAA_St_Level.forEach((eLevel, iLevel) => {
                    this.BOARD_TEST_2AC.forEach((eBoard, iBoard) => {
                        if (eLevel.ft == eBoard.level && eBoard.ddd && !isNaN(eBoard.ddd)) {
                            ppaa2List.push({ board: eBoard, level: eLevel });
                        }
                    });
                });

                if (ppaa2List.length > 0) {
                    /**
                     * New Update  2024-06-07
                     */
                    let idxN = 0;
                    let sumN = 0;
                    listPnPn[idxN] = "";
                    PnPn[idxN] = "";
                    for (var i = 0; i < ppaa2List.length; i++) {
                        sumN++;

                        if (sumN == 1) {
                            PnPn[idxN] = String(ppaa2List[i].level.mb).substring(0, 2);
                            listPnPn[idxN] = "";
                        }

                        nn[idxN] = sumN;
                        listPnPn[idxN] = listPnPn[idxN] + ppaa2List[i].board.dddff + " ";

                        if (sumN == 3) {
                            sumN = 0;
                            idxN++;
                            if (i < ppaa2List.length - 1) {
                                listPnPn[idxN] = "";
                                PnPn[idxN] = "";
                                nn[idxN] = "";
                            }
                        }
                    }

                    this.sandi_pibal = PPAA_1 + "\n";
                    if (nn && nn.length > 0) {
                        for (var i = 0; i < nn.length; i++) {
                            this.sandi_pibal = this.sandi_pibal + "55" + nn[i] + PnPn[i] + " " + listPnPn[i] + "\n";
                        }
                    } else {
                        this.sandi_pibal = this.sandi_pibal + "NIL=" + "\n\n";
                    }

                    // angin maximum PPAA kode 6 dan 7
                    let ffMax = null;
                    let petunjuk = "";

                    let tmpPetunjuk67 = this.BOARD_TEST_2AC[this.BOARD_TEST_2AC.length - 1];
                    if (tmpPetunjuk67.level > 19000 && tmpPetunjuk67.level <= 55000 && tmpPetunjuk67.ff > 60) {
                        let isPetunjuk6 = true;
                        this.BOARD_TEST_2AC.forEach((eBoard, iBoard) => {
                            if (eBoard.ff > tmpPetunjuk67.ff) {
                                isPetunjuk6 = false;
                                return;
                            }
                        });

                        ffMax = isPetunjuk6 ? tmpPetunjuk67 : null;
                        petunjuk = isPetunjuk6 ? "6" : "";
                    } else {
                        let isPetunjuk7 = false;
                        this.BOARD_TEST_2AC.forEach((eBoard, iBoard) => {
                            if (eBoard.level > 19000 && eBoard.level <= 55000 && eBoard.ff > 60) {
                                tmpPetunjuk67 = eBoard;
                                isPetunjuk7 = true;
                            }
                        });

                        ffMax = isPetunjuk7 ? tmpPetunjuk67 : null;
                        petunjuk = isPetunjuk7 ? "7" : "";
                    }

                    if (ffMax) {
                        let lvl = parseInt(ffMax.level / 3.3 / 10);
                        PPAA_3 = petunjuk + this.normalisasiLevel(lvl) + " " + ffMax.dddff;
                    }

                    this.sandi_pibal = this.sandi_pibal + PPAA_3 + "=" + "\n\n";
                } else {
                    this.sandi_pibal = PPAA_1 + "\n";
                    this.sandi_pibal = this.sandi_pibal + "NIL=" + "\n\n";
                }

                let fix_ppaa = this.sandi_pibal.substring(0, this.sandi_pibal.length - 2);

                this.arr_sandi_pibal.push(this.gts_message.header_ppaa + " " + ("" + this.tanggal.split("-")[2] + this.jam.replace(":", "")) + "\n" + fix_ppaa);
                this.sandi_pibal_with_header = this.gts_message.header_ppaa + " " + ("" + this.tanggal.split("-")[2] + this.jam.replace(":", "")) + "\n" + fix_ppaa;

                /*
                 ** PPBB - pengenal 9
                 ** 1000 - 9000 = 0
                 ** 10000 - 19000 = 1
                 ** 20000 - 29000 = 2
                 ** 30000 - 39000 = 3
                 ** significant level dimulai dari titik elevasi stasiun (metadata)
                 ** '/' : menandakan permukaan
                 */
                //  console.log('PPBB')
                let PPBB_1 = "PPBB " + YY + GG[0] + a4 + " " + this.t_wmoid;
                let PPBB_2_0 = ["90/"];
                let PPBB_2_0_content = [""];
                let puluhan = 0;
                let nxt_puluhan = 0;
                let count = 0;
                let max = 0;
                let lPPBB = 0;
                let idx = 0;
                // getAll Significant
                let BOARD_SIGNIFICANT = [];
                this.BOARD_TEST_2.forEach((eBoard, iBoard) => {
                    if (!isNaN(eBoard.ddd) && (eBoard.sl || eBoard.slff) && eBoard.level <= 55000) {
                        BOARD_SIGNIFICANT.push(eBoard);
                    }
                });

                if (BOARD_SIGNIFICANT.length > 1) {
                    for (var i = 0; i < BOARD_SIGNIFICANT.length; i++) {
                        if (i == 0) {
                            // permukaan
                            PPBB_2_0[0] = "90/";
                            PPBB_2_0_content[0] = BOARD_SIGNIFICANT[i].dddff + " ";
                        } else {
                            // nxt_puluhan = BOARD_SIGNIFICANT[i].level <= 9000 ? 0 : BOARD_SIGNIFICANT[i].level <= 19000 ? 1 : BOARD_SIGNIFICANT[i].level <= 29000 ? 2 : BOARD_SIGNIFICANT[i].level <= 39000 ? 3 : 4;
                            nxt_puluhan =
                                BOARD_SIGNIFICANT[i].level <= 9000
                                    ? 0
                                    : BOARD_SIGNIFICANT[i].level <= 19000
                                    ? 1
                                    : BOARD_SIGNIFICANT[i].level <= 29000
                                    ? 2
                                    : BOARD_SIGNIFICANT[i].level <= 39000
                                    ? 3
                                    : BOARD_SIGNIFICANT[i].level <= 49000
                                    ? 4
                                    : BOARD_SIGNIFICANT[i].level <= 55000
                                    ? 5
                                    : 6;

                            if (PPBB_2_0[idx].length >= 5) {
                                PPBB_2_0_content[idx] = PPBB_2_0_content[idx] + " \n";

                                idx++;
                                puluhan = nxt_puluhan;
                                PPBB_2_0.push("9" + puluhan);
                                PPBB_2_0_content.push("");

                                PPBB_2_0[idx] = PPBB_2_0[idx] + this.satuanLevel(BOARD_SIGNIFICANT[i].level) + "";
                                PPBB_2_0_content[idx] = PPBB_2_0_content[idx] + BOARD_SIGNIFICANT[i].dddff + " ";
                            } else {
                                if (nxt_puluhan == puluhan) {
                                    PPBB_2_0[idx] = PPBB_2_0[idx] + this.satuanLevel(BOARD_SIGNIFICANT[i].level) + "";
                                    PPBB_2_0_content[idx] = PPBB_2_0_content[idx] + BOARD_SIGNIFICANT[i].dddff + " ";
                                } else {
                                    let lastIndexPPBBExist = PPBB_2_0[idx].length;
                                    for (var n = lastIndexPPBBExist; n < 5; n++) {
                                        PPBB_2_0[idx] = PPBB_2_0[idx] + "/";
                                        PPBB_2_0_content[idx] = PPBB_2_0_content[idx] + " ";
                                        if (n == 4) {
                                            PPBB_2_0_content[idx] = PPBB_2_0_content[idx] + " \n";
                                        }
                                    }

                                    idx++;
                                    puluhan = nxt_puluhan;
                                    PPBB_2_0.push("9" + puluhan);
                                    PPBB_2_0_content.push("");

                                    PPBB_2_0[idx] = PPBB_2_0[idx] + this.satuanLevel(BOARD_SIGNIFICANT[i].level) + "";
                                    PPBB_2_0_content[idx] = PPBB_2_0_content[idx] + BOARD_SIGNIFICANT[i].dddff + " ";
                                }
                            }
                        }

                        if (i == BOARD_SIGNIFICANT.length - 1) {
                            let lastIndexPPBBExist = PPBB_2_0[idx].length;
                            for (var n = lastIndexPPBBExist; n < 5; n++) {
                                PPBB_2_0[idx] = PPBB_2_0[idx] + "/";
                                PPBB_2_0_content[idx] = PPBB_2_0_content[idx] + " ";
                                if (n == 4) {
                                    PPBB_2_0_content[idx] = PPBB_2_0_content[idx] + " \n";
                                }
                            }
                        }
                    }
                } else {
                    PPBB_2_0[0] = "NIL";
                }

                this.sandi_pibal = this.sandi_pibal + PPBB_1 + "\n";
                let fix_ppbb = PPBB_1 + "\n";
                for (var n = 0; n < PPBB_2_0.length; n++) {
                    this.sandi_pibal = this.sandi_pibal + PPBB_2_0[n] + " " + PPBB_2_0_content[n];
                    fix_ppbb = fix_ppbb + PPBB_2_0[n] + " " + PPBB_2_0_content[n];

                    if (n == PPBB_2_0.length - 1) {
                        this.sandi_pibal = this.sandi_pibal.trim();
                        this.sandi_pibal = this.sandi_pibal + "=";
                        fix_ppbb = fix_ppbb.trim() + "=";
                    }
                }
                this.arr_sandi_pibal.push(this.gts_message.header_ppbb + " " + ("" + this.tanggal.split("-")[2] + this.jam.replace(":", "")) + "\n" + fix_ppbb);
                this.sandi_pibal_with_header = this.sandi_pibal_with_header + this.gts_message.header_ppbb + " " + ("" + this.tanggal.split("-")[2] + this.jam.replace(":", "")) + "\n" + fix_ppbb;

                /* Bagian C
                 * Seksi1 : YYGGa4 ; YY = 50 + tanggal ; GG
                 *
                 */
                const YY_C = String(parseInt(this.tanggal.substring(this.tanggal.length - 2)) + 50);
                const GG_C = this.jam.split(":");
                const PPCC_1 = "PPCC " + YY_C + GG_C[0] + a4 + " " + this.t_wmoid;

                /*
                 * Seksi2 : 55nP1P1 ddfff ddfff ddfff
                 */
                //  console.log('Seksi 2')
                let n1_c = null;
                let P1P1_c = null;
                let listP1P1_c = "";

                let n2_c = null;
                let P2P2_c = null;
                let PPCC_2_1 = null;
                let PPCC_2_2 = null;

                let listP2P2_c = "";

                PnPn = [];
                nn = [];
                listPnPn = [];

                let PPCC_3 = "77999";
                let ppcc2List = [];
                ref_PPCC_St_Level.forEach((eLevel, iLevel) => {
                    this.BOARD_TEST_2AC.forEach((eBoard, iBoard) => {
                        // if (eLevel.ft == eBoard.level) {
                        if (eLevel.ft == eBoard.level && eBoard.ddd && !isNaN(eBoard.ddd)) {
                            ppcc2List.push({ board: eBoard, level: eLevel });
                        }
                    });
                });
                // console.log("ppcc2List : ", ppcc2List);
                if (ppcc2List.length > 0) {
                    /**
                     * New Update  2024-06-07
                     */
                    let idxN = 0;
                    let sumN = 0;
                    listPnPn[idxN] = "";
                    PnPn[idxN] = "";
                    for (var i = 0; i < ppcc2List.length; i++) {
                        sumN++;

                        if (sumN == 1) {
                            PnPn[idxN] = String(ppcc2List[i].level.mb).substring(0, 2);
                            listPnPn[idxN] = "";
                        }

                        nn[idxN] = sumN;
                        listPnPn[idxN] = listPnPn[idxN] + ppcc2List[i].board.dddff + " ";

                        if (sumN == 3) {
                            sumN = 0;
                            idxN++;
                            if (i < ppcc2List.length - 1) {
                                listPnPn[idxN] = "";
                                PnPn[idxN] = "";
                                nn[idxN] = "";
                            }
                        }
                    }

                    this.sandi_pibal = PPCC_1 + "\n";
                    if (nn && nn.length > 0) {
                        for (var i = 0; i < nn.length; i++) {
                            this.sandi_pibal = this.sandi_pibal + "55" + nn[i] + PnPn[i] + " " + listPnPn[i] + "\n";
                        }
                    } else {
                        this.sandi_pibal = this.sandi_pibal + "NIL=" + "\n\n";
                    }

                    // angin maximum PPAA kode 6 dan 7
                    let ffMax = null;
                    let petunjuk = "";

                    let tmpPetunjuk67 = this.BOARD_TEST_2AC[this.BOARD_TEST_2AC.length - 1];
                    if (tmpPetunjuk67.level > 19000 && tmpPetunjuk67.level <= 95000 && tmpPetunjuk67.ff > 60) {
                        let isPetunjuk6 = true;
                        this.BOARD_TEST_2AC.forEach((eBoard, iBoard) => {
                            if (eBoard.ff > tmpPetunjuk67.ff) {
                                isPetunjuk6 = false;
                                return;
                            }
                        });

                        ffMax = isPetunjuk6 ? tmpPetunjuk67 : null;
                        petunjuk = isPetunjuk6 ? "6" : "";
                    } else {
                        let isPetunjuk7 = false;
                        this.BOARD_TEST_2AC.forEach((eBoard, iBoard) => {
                            if (eBoard.level > 19000 && eBoard.level <= 95000 && eBoard.ff > 60) {
                                tmpPetunjuk67 = eBoard;
                                isPetunjuk7 = true;
                            }
                        });

                        ffMax = isPetunjuk7 ? tmpPetunjuk67 : null;
                        petunjuk = isPetunjuk7 ? "7" : "";
                    }

                    if (ffMax) {
                        let lvl = parseInt(ffMax.level / 3.3 / 10);
                        PPCC_3 = petunjuk + this.normalisasiLevel(lvl) + " " + ffMax.dddff;
                    }

                    this.sandi_pibal = this.sandi_pibal + PPCC_3 + "=" + "\n\n";
                }
                //else {
                //    this.sandi_pibal = PPCC_1 + "\n";
                //    this.sandi_pibal = this.sandi_pibal + "NIL=" + "\n\n";
                //}

                if (ppcc2List.length > 0) {
                    console.log("ppcc2List", ppcc2List);
                    let fix_ppcc = this.sandi_pibal.substring(0, this.sandi_pibal.length - 2);

                    this.arr_sandi_pibal.push(this.gts_message.header_ppcc + " " + ("" + this.tanggal.split("-")[2] + this.jam.replace(":", "")) + "\n" + fix_ppcc);
                    this.sandi_pibal_with_header = this.sandi_pibal_with_header + this.gts_message.header_ppcc + " " + ("" + this.tanggal.split("-")[2] + this.jam.replace(":", "")) + "\n" + fix_ppcc;
                }
                //else {
                //    if (PPBB_2_0[0] == "NIL") {
                //        let fix_ppcc = this.sandi_pibal.substring(0, this.sandi_pibal.length - 2);

                //        this.arr_sandi_pibal.push(this.gts_message.header_ppcc + " " + ("" + this.tanggal.split("-")[2] + this.jam.replace(":", "")) + "\n" + fix_ppcc);
                //        this.sandi_pibal_with_header = this.sandi_pibal_with_header + this.gts_message.header_ppcc + " " + ("" + this.tanggal.split("-")[2] + this.jam.replace(":", "")) + "\n" + fix_ppcc;
                //    }
                //}
                // if (ppcc2List.length > 0) {
                //   if (ppcc2List.length > 3) {
                //     n1_c = 3;
                //     P1P1_c = String(ppcc2List[0].level.mb).substring(0, 2);
                //     for (var i = 0; i < ppcc2List.length; i++) {
                //       listP1P1_c = listP1P1_c + ppcc2List[i].board.dddff + " ";
                //       if (i + 1 == n1_c) {
                //         break;
                //       }
                //     }

                //     n2_c = ppcc2List.length % 3;
                //     P2P2_c = String(ppcc2List[3].level.mb).substring(0, 2);
                //     for (var i = 3; i < ppcc2List.length; i++) {
                //       listP2P2_c = listP2P2_c + ppcc2List[i].board.dddff + " ";
                //     }
                //   } else {
                //     n1_c = ppcc2List.length % 3;
                //     P1P1_c = String(ppcc2List[0].level.mb).substring(0, 2);
                //     for (var i = 0; i < ppcc2List.length; i++) {
                //       listP1P1_c = listP1P1_c + ppcc2List[i].board.dddff + " ";
                //     }
                //   }

                //   // let PPAA_2_1 = "55" + n1 + P1P1 + " " + listP1P1;
                //   // let PPAA_2_2 = P2P2 ? "55" + n2 + P2P2 + " " + listP2P2 : "";
                //   PPCC_2_1 = "55" + n1_c + P1P1_c + " " + listP1P1_c;
                //   PPCC_2_2 = P2P2_c ? "55" + n2_c + P2P2_c + " " + listP2P2_c : "";

                //   // angin maximum PPCC kode 6 dan 7
                //   let ffMax_c = null;
                //   let petunjuk_c = "";

                //   let tmpPetunjuk67_c = this.BOARD_TEST_2[this.BOARD_TEST_2.length - 1];
                //   if (tmpPetunjuk67_c.level > 19000 && tmpPetunjuk67_c.level <= 95000 && tmpPetunjuk67_c.ff > 60) {
                //     let isPetunjuk6_c = true;
                //     this.BOARD_TEST_2.forEach((eBoard, iBoard) => {
                //       if (eBoard.ff > tmpPetunjuk67_c.ff) {
                //         isPetunjuk6_c = false;
                //         return;
                //       }
                //     });

                //     ffMax_c = isPetunjuk6_c ? tmpPetunjuk67_c : null;
                //     petunjuk_c = isPetunjuk6_c ? "6" : "";
                //   } else {
                //     let isPetunjuk7_c = false;
                //     this.BOARD_TEST_2.forEach((eBoard, iBoard) => {
                //       if (eBoard.level > 19000 && eBoard.level <= 95000 && eBoard.ff > 60) {
                //         tmpPetunjuk67_c = eBoard;
                //         isPetunjuk7_c = true;
                //       }
                //     });

                //     ffMax_c = isPetunjuk7_c ? tmpPetunjuk67_c : null;
                //     petunjuk_c = isPetunjuk7_c ? "7" : "";
                //   }

                //   if (ffMax_c) {
                //     let lvl = parseInt(ffMax_c.level / 3.3 / 10);
                //     PPCC_3 = petunjuk_c + this.normalisasiLevel(lvl) + " " + ffMax_c.dddff;
                //   }

                //   // console.log("ffMax : ", ffMax);

                //   // this.sandi_pibal = PPAA_1 + "\n" + PPAA_2_1 + "\n" + PPAA_2_2 + " " + PPAA_3 + "\n\n";
                //   this.sandi_pibal += "\n\n";
                //   this.sandi_pibal += PPCC_1 + "\n" + PPCC_2_1;
                //   let fix_ppcc = PPCC_1 + "\n" + PPCC_2_1;
                //   if (PPCC_2_2 == "") {
                //     this.sandi_pibal = this.sandi_pibal + " " + PPCC_3 + "=" + "\n\n";
                //     fix_ppcc = fix_ppcc + " " + PPCC_3 + "=" + "\n\n";
                //   } else {
                //     this.sandi_pibal = this.sandi_pibal + "\n" + PPCC_2_2 + " " + PPCC_3 + "=" + "\n\n";
                //     fix_ppcc = fix_ppcc + "\n" + PPCC_2_2 + " " + PPCC_3 + "=" + "\n\n";
                //   }
                //   // this.arr_sandi_pibal.push(fix_ppcc)
                //   this.arr_sandi_pibal.push(this.gts_message.header_ppcc + " " + (""+this.tanggal.split('-')[2]+this.jam.replace(':','')) + "\n" + fix_ppcc)
                //   this.sandi_pibal_with_header = this.sandi_pibal_with_header + this.gts_message.header_ppcc + " " + (""+this.tanggal.split('-')[2]+this.jam.replace(':','')) + "\n" + fix_ppcc
                // }

                // else {
                //   // console.log('ppaa2List else ', PPAA_1, PPAA_2_1, PPAA_2_1)
                //   this.sandi_pibal += "\n\n"
                //   this.sandi_pibal += this.sandi_pibal + PPCC_1 + "\n" + (PPCC_2_1 ? PPCC_2_1 : "");
                //   if (!PPCC_2_2 || PPCC_2_2 == "" ) {
                //     this.sandi_pibal = this.sandi_pibal + " " + PPCC_3+"=" + "\n\n";
                //   } else {
                //     this.sandi_pibal = this.sandi_pibal + "\n" + PPCC_2_2 ? PPCC_2_2 : "" + " " + PPCC_3+"=" + "\n\n";
                //   }
                // }

                /*
                 ** PPDD - pengenal 9
                 ** 56000 - 59000 = 0
                 ** 60000 - 69000 = 1
                 ** 70000 - 79000 = 2
                 ** 80000 - 89000 = 3
                 ** 90000 - 99000 = 4
                 ** significant level dimulai dari titik elevasi stasiun (metadata)
                 ** '/' : menandakan permukaan
                 */
                //  console.log('PPBB')
                let PPDD_1 = "PPDD " + YY + GG[0] + a4 + " " + this.t_wmoid;
                let PPDD_2_0 = ["95"];
                let PPDD_2_0_content = [""];
                let puluhan_d = 5;
                let nxt_puluhan_d = 0;
                let count_d = 0;
                let max_d = 0;
                let lPPBB_d = 0;
                let idx_d = 0;
                // getAll Significant
                let BOARD_SIGNIFICANT_D = [];
                this.BOARD_TEST_2.forEach((eBoard, iBoard) => {
                    if (!isNaN(eBoard.ddd) && (eBoard.sl || eBoard.slff) && eBoard.level > 55000) {
                        BOARD_SIGNIFICANT_D.push(eBoard);
                    }
                });
                console.log("PPDD - BOARD_SIGNIFICANT_D", BOARD_SIGNIFICANT_D.length, BOARD_SIGNIFICANT_D);

                if (BOARD_SIGNIFICANT_D.length > 0) {
                    for (var i = 0; i < BOARD_SIGNIFICANT_D.length; i++) {
                        // if (i == 0) {
                        //     // permukaan
                        //     PPDD_2_0[0] = "90/";
                        //     PPDD_2_0_content[0] = BOARD_SIGNIFICANT_D[i].dddff + " ";
                        // } else {
                        // nxt_puluhan = BOARD_SIGNIFICANT[i].level <= 9000 ? 0 : BOARD_SIGNIFICANT[i].level <= 19000 ? 1 : BOARD_SIGNIFICANT[i].level <= 29000 ? 2 : BOARD_SIGNIFICANT[i].level <= 39000 ? 3 : 4;
                        nxt_puluhan_d =
                            BOARD_SIGNIFICANT_D[i].level <= 59000 ? 5 : BOARD_SIGNIFICANT_D[i].level <= 69000 ? 6 : BOARD_SIGNIFICANT_D[i].level <= 79000 ? 7 : BOARD_SIGNIFICANT_D[i].level <= 89000 ? 8 : BOARD_SIGNIFICANT_D[i].level <= 99000 ? 9 : 0;

                        if (PPDD_2_0[idx_d].length >= 5) {
                            PPDD_2_0_content[idx_d] = PPDD_2_0_content[idx_d] + " \n";

                            idx_d++;
                            puluhan_d = nxt_puluhan_d;
                            PPDD_2_0.push("9" + puluhan_d);
                            PPDD_2_0_content.push("");

                            PPDD_2_0[idx_d] = PPDD_2_0[idx_d] + this.satuanLevel(BOARD_SIGNIFICANT_D[i].level) + "";
                            PPDD_2_0_content[idx_d] = PPDD_2_0_content[idx_d] + BOARD_SIGNIFICANT_D[i].dddff + " ";
                            console.log("D1", idx_d, PPDD_2_0[idx_d], nxt_puluhan_d, puluhan_d);
                        } else {
                            if (nxt_puluhan_d == puluhan_d) {
                                PPDD_2_0[idx_d] = PPDD_2_0[idx_d] + this.satuanLevel(BOARD_SIGNIFICANT_D[i].level) + "";
                                PPDD_2_0_content[idx_d] = PPDD_2_0_content[idx_d] + BOARD_SIGNIFICANT_D[i].dddff + " ";
                                console.log("D2 if", idx_d, PPDD_2_0[idx_d], nxt_puluhan_d, puluhan_d);
                            } else {
                                let lastIndexPPDDExist = PPDD_2_0[idx_d].length;
                                for (var n = lastIndexPPDDExist; n < 5; n++) {
                                    PPDD_2_0[idx_d] = PPDD_2_0[idx_d] + "/";
                                    PPDD_2_0_content[idx_d] = PPDD_2_0_content[idx_d] + " ";
                                    if (n == 4) {
                                        PPDD_2_0_content[idx_d] = PPDD_2_0_content[idx_d] + " \n";
                                    }
                                }

                                idx_d++;
                                puluhan_d = nxt_puluhan_d;
                                PPDD_2_0.push("9" + puluhan_d);
                                PPDD_2_0_content.push("");

                                PPDD_2_0[idx_d] = PPDD_2_0[idx_d] + this.satuanLevel(BOARD_SIGNIFICANT_D[i].level) + "";
                                PPDD_2_0_content[idx_d] = PPDD_2_0_content[idx_d] + BOARD_SIGNIFICANT_D[i].dddff + " ";

                                console.log("D2 else", idx_d, PPDD_2_0[idx_d], nxt_puluhan_d, puluhan_d);
                            }
                        }
                        // }

                        if (i == BOARD_SIGNIFICANT_D.length - 1) {
                            let lastIndexPPDDExist = PPDD_2_0[idx_d].length;
                            for (var n = lastIndexPPDDExist; n < 5; n++) {
                                PPDD_2_0[idx_d] = PPDD_2_0[idx_d] + "/";
                                PPDD_2_0_content[idx_d] = PPDD_2_0_content[idx_d] + " ";
                                if (n == 4) {
                                    PPDD_2_0_content[idx_d] = PPDD_2_0_content[idx_d] + " \n";
                                }
                            }
                        }
                    }
                }
                // else {
                //     PPDD_2_0[0] = "NIL";
                // }
                console.log("PPDD_2_0", PPDD_2_0, PPDD_2_0_content);
                if (BOARD_SIGNIFICANT_D.length > 0) {
                    this.sandi_pibal = this.sandi_pibal + PPDD_1 + "\n";
                    let fix_ppdd = PPDD_1 + "\n";
                    for (var n = 0; n < PPDD_2_0.length; n++) {
                        this.sandi_pibal = this.sandi_pibal + PPDD_2_0[n] + " " + PPDD_2_0_content[n];
                        fix_ppdd = fix_ppdd + PPDD_2_0[n] + " " + PPDD_2_0_content[n];

                        if (n == PPDD_2_0.length - 1) {
                            this.sandi_pibal = this.sandi_pibal.trim();
                            this.sandi_pibal = this.sandi_pibal + "=";
                            fix_ppdd = fix_ppdd.trim() + "=";
                        }
                    }
                    this.arr_sandi_pibal.push(this.gts_message.header_ppdd + " " + ("" + this.tanggal.split("-")[2] + this.jam.replace(":", "")) + "\n" + fix_ppdd);
                    this.sandi_pibal_with_header = this.sandi_pibal_with_header + this.gts_message.header_ppdd + " " + ("" + this.tanggal.split("-")[2] + this.jam.replace(":", "")) + "\n" + fix_ppdd;
                }
            }
        },

        sandiBagianAC(type) {
            //PPAA dari 1000 - 100 mbs ; {mb:1000, ft:200}
            //PPCC dan PPDD dari 70 - 10 mbs

            let sandi = "";
            const ref_PPAA_St_Level = [
                { mb: 850, ft: 5000 },
                { mb: 700, ft: 10000 },
                { mb: 500, ft: 19000 },
                { mb: 400, ft: 25000 },
                { mb: 300, ft: 31000 },
                { mb: 250, ft: 36000 },
                { mb: 200, ft: 41000 },
                { mb: 150, ft: 47000 },
                { mb: 100, ft: 55000 },
            ];
            const ref_PPCC_St_Level = [
                { mb: 70, ft: 61000 },
                { mb: 50, ft: 67000 },
                { mb: 30, ft: 78000 },
                { mb: 20, ft: 86000 },
                { mb: 10, ft: 95000 },
            ];

            const ref_Regional_SL = [0, 3000, 7000, 14000, 80000];

            /* Bagian A
             * Seksi1 : YYGGa4 ; YY = 50 + tanggal ; GG
             *
             */
            const a4 = this.ALAT_PIBAL;
            const YY = String(parseInt(this.tanggal.substring(this.tanggal.length - 2)) + 50);
            const GG = this.jam.split(":");
            const PPAA_1 = "PPAA " + YY + GG[0] + a4 + " " + this.t_wmoid;

            /*
             * Seksi2 : 55nP1P1 ddfff ddfff ddfff
             */
            let n1 = null;
            let P1P1 = null;
            let listP1P1 = "";

            let n2 = null;
            let P2P2 = null;
            let listP2P2 = "";

            let PPAA_3 = "77999";
            let ppaa2List = [];

            if (type == "A") {
                ref_PPAA_St_Level.forEach((eLevel, iLevel) => {
                    this.BOARD_TEST_2.forEach((eBoard, iBoard) => {
                        if (eLevel.ft == eBoard.level) {
                            ppaa2List.push({ board: eBoard, level: eLevel });
                        }
                    });
                });
            } else if (type == "C") {
                ref_PPCC_St_Level.forEach((eLevel, iLevel) => {
                    this.BOARD_TEST_2.forEach((eBoard, iBoard) => {
                        if (eLevel.ft == eBoard.level) {
                            ppaa2List.push({ board: eBoard, level: eLevel });
                        }
                    });
                });
            }

            // console.log("ppaa2List : ", ppaa2List);
            if (ppaa2List.length > 0) {
                if (ppaa2List.length > 3) {
                    n1 = 3;
                    P1P1 = String(ppaa2List[0].level.mb).substring(0, 2);
                    for (var i = 0; i < ppaa2List.length; i++) {
                        listP1P1 = listP1P1 + ppaa2List[i].board.dddff + " ";
                        if (i + 1 == n1) {
                            break;
                        }
                    }

                    n2 = ppaa2List.length % 3;
                    P2P2 = String(ppaa2List[3].level.mb).substring(0, 2);
                    for (var i = 3; i < ppaa2List.length; i++) {
                        listP2P2 = listP2P2 + ppaa2List[i].board.dddff + " ";
                    }
                } else {
                    n1 = ppaa2List.length % 3;
                    P1P1 = String(ppaa2List[0].level.mb).substring(0, 2);
                    for (var i = 0; i < ppaa2List.length; i++) {
                        listP1P1 = listP1P1 + ppaa2List[i].board.dddff + " ";
                    }
                }

                let PPAA_2_1 = "55" + n1 + P1P1 + " " + listP1P1;
                let PPAA_2_2 = P2P2 ? "55" + n2 + P2P2 + " " + listP2P2 : "";

                // angin maximum PPAA kode 6 dan 7
                let ffMax = null;
                let isMore500mbs = false;
                this.BOARD_TEST_2.forEach((eBoard, iBoard) => {
                    if (eBoard.level < 19000) {
                        isMore500mbs = false;
                    } else {
                        isMore500mbs = true;
                    }
                });

                /** Petunjuk 6 & 7 */
                if (isMore500mbs) {
                    this.BOARD_TEST_2.forEach((eBoard, iBoard) => {
                        if (eBoard.level > 19000 && eBoard.ff > 60) {
                            if (ffMax) {
                                if (eBoard.ff > ffMax.ff) {
                                    ffMax = eBoard;
                                }
                            } else {
                                ffMax = eBoard;
                            }
                        }
                    });
                    if (ffMax) {
                        let lvl = parseInt(ffMax.level / 3.3 / 10);
                        PPAA_3 = "6" + this.normalisasiLevel(lvl) + " " + ffMax.dddff;
                    }
                } else {
                    // kode 7, lapisan angin maximum dibawah 500mbs
                    let eBoard = this.BOARD_TEST_2[this.BOARD_TEST_2.length - 1];
                    ffMax = eBoard.ff > 60 ? eBoard : null;
                    if (ffMax) {
                        let lvl = parseInt(ffMax.level / 3.3 / 10);
                        PPAA_3 = "7" + this.normalisasiLevel(lvl) + " " + ffMax.dddff;
                    }
                }
                // console.log("ffMax : ", ffMax);

                this.sandi_pibal = PPAA_1 + "\n" + PPAA_2_1 + "\n" + PPAA_2_2 + "\n" + PPAA_3 + "\n\n";
            }

            return sandi;
        },

        sandiBagianA() {
            return "";
        },

        satuanLevel(value) {
            if (value < 10000) {
                return value / 1000;
            } else if (value < 20000) {
                return value / 1000 - 10;
            } else if (value < 30000) {
                return value / 1000 - 20;
            } else if (value < 40000) {
                return value / 1000 - 30;
            } else if (value < 50000) {
                return value / 1000 - 40;
            } else if (value < 60000) {
                return value / 1000 - 50;
            } else if (value < 70000) {
                return value / 1000 - 60;
            } else if (value < 80000) {
                return value / 1000 - 70;
            } else if (value < 90000) {
                return value / 1000 - 80;
            } else if (value < 100000) {
                return value / 1000 - 90;
            } else if (value < 110000) {
                return (value - 100000) / 1000;
            } else if (value < 120000) {
                return (value - 100000) / 1000 - 10;
            } else if (value < 130000) {
                return (value - 100000) / 1000 - 20;
            }

            return 0;
        },

        nextTinggiBalon(tb, n) {
            if (tb > 500 * n + 250) {
                return this.nextTinggiBalon(tb, n + 1);
            } else {
                return 500 * n + 250;
            }
        },

        showLevel() {
            // console.log('showLevel')
            this.LEVEL = [];
            var elevasi_station_feet = parseFloat(this.ELEVASI_STATION); // * 3.28084;

            if (elevasi_station_feet < 250) {
                let tb_awal = 250;
                this.PEMBACAAN.forEach((element, index) => {
                    element.tinggi_balon = tb_awal;
                    if (element.tinggi_balon == 250) {
                        element.st_hitung = false;
                    } else {
                        element.st_hitung = true;
                    }
                    tb_awal = tb_awal + 500;
                });
                let sec = Math.round(((250 - elevasi_station_feet) / 500) * 60);
                this.firstReadingMessage = "Pembacaan pertama dilakukan " + sec + " detik setelah lepas Balon. Pembacaan pertama tidak masuk perhitungan karena elevasi dibawah 250 feet.";
            } else {
                let tb_awal = this.nextTinggiBalon(elevasi_station_feet, 1);
                this.PEMBACAAN.forEach((element, index) => {
                    element.tinggi_balon = tb_awal;
                    if (index == 0 && element.tinggi_balon - elevasi_station_feet < 500 && element.tinggi_balon % 1000 == 250) {
                        element.st_hitung = false;
                    } else {
                        element.st_hitung = true;
                    }
                    tb_awal = tb_awal + 500;
                });
                // let sec = Math.round(((750 - elevasi_station_feet) / 500) * 60)
                let base = this.getBaseElevasi(elevasi_station_feet);
                let sec = Math.round(((base - elevasi_station_feet) / 500) * 60);
                // console.log('base', base)
                this.firstReadingMessage = "Pembacaan pertama dilakukan " + sec + " detik setelah lepas Balon.";
            }

            for (var a = 0; a < this.PEMBACAAN.length; a++) {
                if (a < this.PEMBACAAN.length - 1) {
                    let element1 = this.PEMBACAAN[a];
                    let element2 = this.PEMBACAAN[a + 1];
                    if (element1.st_hitung) {
                        this.LEVEL.push(element2.tinggi_balon - 250);
                        a++;
                    } else {
                        this.LEVEL.push(-1);
                    }
                }
            }

            // console.log('showLevel', this.LEVEL)
        },

        getBaseElevasi(elevasi_station_feet) {
            // console.log('getBaseElevasi', elevasi_station_feet)
            if (elevasi_station_feet && elevasi_station_feet > 1) {
                let isBreak = false;
                for (let n = 1; n <= 100; n++) {
                    // console.log('n = ', n, elevasi_station_feet, (250+(n-1)*500), 250+(n*500) )
                    if (elevasi_station_feet > 250 + (n - 1) * 500 && elevasi_station_feet <= 250 + n * 500) {
                        isBreak = true;
                        return 250 + n * 500;
                    }
                    if (isBreak) {
                        break;
                    }
                }
            }
            return 750;
        },

        toDegree(value) {
            return (value * Math.PI) / 180;
        },

        COTG(value) {
            return 1 / Math.tan(this.toDegree(value));
        },

        SIN(value) {
            return Math.sin(this.toDegree(value));
        },

        COS(value) {
            return Math.cos(this.toDegree(value));
        },

        async listStasiun() {
            try {
                const { data } = await metadata.getAllStasiunWithWMOElevation();

                this.t_station_list = [];
                this.t_wmoid_list = [];
                this.t_elevation_list = [];
                for (var i = 0; i < data.length; i++) {
                    let wmoid = data[i].station_wmo_id;
                    this.t_wmoid_list.push(wmoid);
                    this.t_station_list[wmoid] = data[i].station_id;
                    this.t_path_list[wmoid] = data[i].path;
                    this.t_elevation_list[wmoid] = data[i].station_elevation;
                }
            } catch (error) {
                if (error.response.status === 401) {
                    this.$swal({
                        title: "Sesi anda telah habis.",
                        text: "Logout dan silahkan login kembali",
                        icon: "warning",
                        confirmButtonText: "OK",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
                        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
                        localStorage.removeItem("userData");
                        this.$router.push({ name: "auth-login" });
                    });
                }
            }
        },
        checkData() {
            var tid = this.t_station + "_pibal_" + this.tanggal + "T" + this.jam.substr(0, 2);
            var path = this.t_path + "/" + tid;

            this.checkDataExisting(path);
        },
        checkDataExisting(path) {
            Service.getDataPibal(path)
                .then((response) => {
                    this.$swal({
                        title: "Data Exist",
                        text: "Data tanggal tersebut sudah ada. View data ?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "View",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: "btn btn-primary",
                            cancelButton: "btn btn-outline-danger ml-1",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.value) {
                            this.clearData();
                            this.formShowed = true;
                            this.is_data_exist = true;
                            var data = response.data;
                            // console.log("data.a4 : ", data);
                            this.isEdit = true;
                            this.ALAT_PIBAL = data.a4;
                            // this.ELEVASI_STATION = data.station_elevation, //parseFloat(data.station_elevation * 3.28084).toFixed(1);
                            (this.WIN_DIR_SURFACE = data.wind_dir_surface), (this.WIN_SPEED_SURFACE = data.wind_speed_surface);
                            this.sandi_pibal = data.sandi_pibal;
                            this.sandi_pibal_with_header = data.sandi_pibal_with_header;
                            this.arr_sandi_pibal = data.sandi_pibal_with_header_array ? data.sandi_pibal_with_header_array : [];
                            this.alasan = data.penghentian_pengamatan;
                            // this.t_observer = `${data.observer_id}`;
                            let observer_valid = this.list_observer.find((el) => el.id === data.observer_id);
                            if (observer_valid) {
                                this.t_observer = data.observer_id;
                            } else {
                                this.t_observer = null;
                            }
                            // console.log("this.ALAT_PIBAL : ", this.ALAT_PIBAL);
                            let i = 0;
                            for (var key in data.pembacaan_azimuth_elevasi) {
                                if (i > 9) {
                                    // console.log(i)
                                    this.minNumPembacaan = this.minNumPembacaan + 1;
                                    this.PEMBACAAN.push({
                                        azimut: data.pembacaan_azimuth_elevasi[key].azimuth,
                                        elevasi: data.pembacaan_azimuth_elevasi[key].elevasi,
                                        tinggi_balon: 0,
                                        dn: 0,
                                        xn: 0,
                                        yn: 0,
                                        st_hitung: false,
                                    });
                                } else {
                                    this.PEMBACAAN[i].azimut = data.pembacaan_azimuth_elevasi[key].azimuth;
                                    this.PEMBACAAN[i].elevasi = data.pembacaan_azimuth_elevasi[key].elevasi;
                                }

                                i++;
                            }
                            this.showLevel();
                        } else if (result.dismiss === "cancel") {
                            this.showLoading = false;
                            this.isEdit = false;
                            this.is_data_exist = false;
                            return;
                        }
                    });
                })
                .catch((error) => {
                    // console.log("error.status: ", error.response);
                    this.is_data_exist = false;
                    if (error.response.status == 401) {
                        this.$swal({
                            title: "Gagal Load Data!",
                            text: "Sesi anda telah habis. Logout dan silahkan login kembali",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                    } else if (error.response.status == 404) {
                        this.clearData();
                        this.isEdit = false;
                        this.formShowed = true;
                        // this.showAlertPopupWithMessage('Data tidak ditemukan/Belum ada data.')
                    }
                    // else {
                    //   this.$swal({
                    //       title: "Error!",
                    //       text: "Terjadi Kesalahan",
                    //       icon: "error",
                    //       customClass: {
                    //         confirmButton: "btn btn-primary",
                    //       },
                    //       buttonsStyling: false,
                    //     });
                    // }
                });
        },
        async listObserver(station_id) {
            var data = await metadata.getObserverStation(station_id);
            this.list_observer = data;
            // console.log('list_observer', this.list_observer)
        },
    },
    watch: {
        tanggal: function (value) {
            this.clearData();
            this.jam = "";
            this.showLevel();
        },
        jam: function (value) {
            // console.log("jam ", value);
            if (!value || value == "") {
                return;
            }
            if (this.t_wmoid == "") {
                this.showAlert("WMO ID");
            } else if (this.observers == "") {
                this.showAlert("Observer");
            } else {
                this.checkData();
            }
        },
        t_wmoid: async function (val) {
            if (val) {
                this.t_station = this.t_station_list[val];
                const { data } = await metadata.getPath("PibalCollect", this.t_station, this.t_path_list[val]);
                this.t_path = data[0].path;
                this.ELEVASI_STATION = parseFloat(this.t_elevation_list[val] * 3.28084).toFixed(1);
                this.listObserver(this.t_station);
                let metaPath = this.t_path_list[val];
                this.t_station_path_selected = this.t_path_list[val];
                let result = await metadata.getMetadataPibal(metaPath, "");
                let bmkgentry = result["BmkgSatu.metadata.bmkgentry.IBMKGEntry"];
                this.t_jam_list = [""]; //: ["", "00:00", "06:00", "12:00", "18:00"],
                if (bmkgentry.is_pibal_00) this.t_jam_list.push("00:00");
                if (bmkgentry.is_pibal_06) this.t_jam_list.push("06:00");
                if (bmkgentry.is_pibal_12) this.t_jam_list.push("12:00");
                if (bmkgentry.is_pibal_18) this.t_jam_list.push("18:00");

                let gts_metadata = result["BmkgSatu.metadata.gts.IGTS"];
                this.gts_message = {
                    is_send_to_gts_switching: gts_metadata.pilot_send_to_bmkgswitching ? gts_metadata.pilot_send_to_bmkgswitching : 0,
                    header_ppaa: gts_metadata.pilot_gts_ppaa_header ? gts_metadata.pilot_gts_ppaa_header : "",
                    header_ppbb: gts_metadata.pilot_gts_ppbb_header ? gts_metadata.pilot_gts_ppbb_header : "",
                    header_ppcc: gts_metadata.pilot_gts_ppcc_header ? gts_metadata.pilot_gts_ppcc_header : "",
                    header_ppdd: gts_metadata.pilot_gts_ppdd_header ? gts_metadata.pilot_gts_ppdd_header : "",
                    header_bilateral: gts_metadata.pilot_gts_ppaa_ppbb_national_bilateral_header ? gts_metadata.pilot_gts_ppaa_ppbb_national_bilateral_header : "",
                };
                this.clearData();
                this.jam = "";
                this.showLevel();
            } else {
                this.t_station = "";
                this.t_path = "";
                this.ELEVASI_STATION = "";
            }
        },
        ELEVASI_STATION: function (value) {
            this.showLevel();
            this.updatePembacaan();
            // console.log('this.PEMBACAAN', this.PEMBACAAN)
        },
        PEMBACAAN: {
            deep: true,
            handler() {
                this.updatePembacaan();
            },
        },
    },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    mounted() {
        // console.log("mounted...");
        var balon = 0;
        this.showLevel();
        this.listStasiun();
        this.deltaY = [];
        this.deltaX = [];
        this.WS = [];
    },

    directives: {
        "b-tooltip": VBTooltip,
    },
};
</script>

<style scoped>
.btn-group-submit {
    float: right;
}
.label-entry1 {
    text-align: center;
    color: black;
    width: 100%;
    /* font-weight: bold !important; */
    border: 1px solid #d1d3e0;
    border-radius: 3px;

    padding: 8px;
}
.label-entry {
    text-align: center;
    color: black;
    width: 100%;
    /* font-weight: bold !important; */
    border-radius: 3px;
    padding: 4px;
}
.row-margin {
    margin-top: 4px;
}
.row-level {
    margin-top: 3px;
}
.rowlvl2 {
    margin-top: 38px;
}
.rowlvl3 {
    margin-top: 70px;
}

.pad2 {
    padding-right: 2px;
    padding-left: 2px;
}
.pad1 {
    padding-right: 6px;
    padding-left: 6px;
}
.mr {
    margin-right: 2px;
}
</style>

<style>
.form-control.is-valid {
    padding-right: 0px;
    padding-left: 0px;
    border-color: #3293e2;
    background-image: none;
    font-weight: 500;
}

.rangecheck {
    background-color: aqua !important;
}

.hide {
    display: none !important;
}

.count-excluded {
    background-color: lightgray;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
