import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper"
import moment from "moment";
import FileSaver from "file-saver";
export default {
 
  async getExtractGts(data) {
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    let userId = userLogin.id;
    // let dateStartConvert = date_start;
    // let dateEndConvert = date_end;
    // file = file.toLowerCase();
    let url = Helper.getBaseUrl()+"/@search?type_name=GTSMessage&_metadata=type_message,timestamp_data,timestamp_sent_data,station_wmo_id,sandi_gts,ttaaii,cccc,need_ftp&_size=10000";
    if (data) {
      for (let key in data) {
        url = url + "&" + key + "=" + data[key]
      }
    }
    try {
      // let url = Helper.getBaseUrl()+"/@search?type_name=GTSMessage&_metadata=type_message,timestamp_data,timestamp_sent_data,station_wmo_id,sandi_gts,ttaaii,cccc&_size=10000";
      let axi = await axios.get(url, Helper.getConfig());
      if(axi.status == 200){
        return axi;
      }else{
        return null;
      }
    } catch (error) {
      return null
    }
  },
  
};
